<template>
    <svg
        id="uuid-c41e1253-b71d-4281-886f-8a8a7263ae52"
        data-name="Ebene 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1270.6401 1056.3700225"
        style="width: 100%; height: 100%"
    >
        <path
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            d="M249.3700073,559.0600128c-137.1600037,0-248.3200073,111.0700073-248.3200073,248.039978s111.1600037,248.1299438,248.3200073,248.1299438,248.3200073-111.0700073,248.3200073-248.1300049-111.1600037-248.1300049-248.3200073-248.1300049v.0900879ZM249.3700073,1031.6000519c-124.019989,0-224.6000061-100.4799805-224.6000061-224.4100342s100.5800018-224.4100342,224.6000061-224.4100342,224.5999756,100.4799805,224.5999756,224.4100342-100.5800171,224.4100342-224.6000061,224.4100342h.0000305Z"
        />
        <path
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            d="M1021.2700317,559.0600128c-137.1600342,0-248.3200073,111.0700073-248.3200073,248.1300049s111.1599731,248.1300049,248.3200073,248.1300049,248.3200684-111.0700073,248.3200684-248.1300049-111.1600342-248.1300049-248.3200684-248.1300049h0ZM1021.2700317,1031.6000519c-124.0200195,0-224.5999756-100.4799805-224.5999756-224.4100342s100.5800171-224.4100342,224.5999756-224.4100342,224.5999756,100.4799805,224.5999756,224.4100342-100.5799561,224.4100342-224.5999756,224.4100342Z"
        />
        <polygon
            class="uuid-97cc222d-5dad-448a-acc4-f70d7c010341"
            points="430.8599975 460.8400116 430.6699951 461.1299896 445.6999633 501.3000031 430.8599975 460.8400116"
        />
        <path
            class="uuid-97cc222d-5dad-448a-acc4-f70d7c010341"
            d="M912.0000122,377.5600128h0c0,.0899963.8499756.0899963.8499756.0899963-.2800293,0-.5700073,0-.8499756-.0899963"
        />
        <path
            class="uuid-97cc222d-5dad-448a-acc4-f70d7c010341"
            d="M912.0000122,352.6100006v.1900024c.6599731-.0899963,1.4199829-.1900024,2.1699829-.1900024h-2.1699829Z"
        />
        <ellipse
            class="uuid-bc0d907c-4f9a-497e-96de-925ff6da40fb"
            cx="1021.2700317"
            cy="807.0899811"
            rx="40.5500488"
            ry="40.460022"
        />
        <line
            class="uuid-bc0d907c-4f9a-497e-96de-925ff6da40fb"
            x1="938.3700073"
            y1="489.3900299"
            x2="940.169934"
            y2="487.969986"
        />
        <polygon
            class="uuid-bb0621cb-5e3e-4487-b549-302cd99d1360"
            points="381.9900024 330.7700043 416.6800048 423.6900177 452.7899902 423.6900177 418.7600219 330.7700043 381.9900024 330.7700043"
        />
        <path
            class="uuid-b5e5dd3a-ad35-4d35-8827-a31f0a141e9d"
            d="M989.9900024,550.6400299l48.4899902,245.2999878c.1899414,1.2299805.3800049,2.3599854.3800049,3.5900269,0,8.5999756-6.0500488,16.2600098-14.8399658,17.960022-9.9300537,1.9899902-19.5699463-4.539978-21.5500488-14.460022l-48.4899902-245.2999878,36.0100098-7.0900269h0Z"
        />
        <polygon
            class="uuid-b5e5dd3a-ad35-4d35-8827-a31f0a141e9d"
            points="938.0000122 477.010025 953.9699829 557.8300323 989.9900024 550.6400299 974.0100219 469.9199982 938.0000122 477.010025"
        />
        <ellipse
            class="uuid-bc0d907c-4f9a-497e-96de-925ff6da40fb"
            cx="249.3700073"
            cy="807.0899811"
            rx="40.5499878"
            ry="40.460022"
        />
        <polygon
            class="uuid-b5e5dd3a-ad35-4d35-8827-a31f0a141e9d"
            points="918.4300048 377.7500153 955.7700317 377.7500153 974.0100219 469.9199982 938.0000122 477.010025 932.419934 448.8400116 918.4300048 377.7500153"
        />
        <path
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            d="M447.6800048,293.3400116h30.3400269c10.2999878,0,18.7199707,8.4100037,18.7199707,18.7200012s-2.0800171,9.8299866-5.4799805,13.230011c-3.4000244,3.3999939-8.0300293,5.480011-13.2299805,5.480011h-30.3400269"
        />
        <path
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            d="M447.6800048,330.7700043h-116.4599915c-10.2999878,0-18.7200012-8.4100037-18.7200012-18.7200012s2.0799866-9.8299866,5.480011-13.230011c3.3999939-3.3999939,8.0299988-5.480011,13.230011-5.480011h116.460022c10.2999878,0,18.7199707,8.4100037,18.7199707,18.7200012s-2.0800171,9.8299866-5.4799805,13.230011c-3.4000244,3.3999939-8.0300293,5.480011-13.2299805,5.480011h-.000061Z"
        />
        <path
            class="uuid-b5e5dd3a-ad35-4d35-8827-a31f0a141e9d"
            d="M574.9199951,819.4799957H248.1399963c-10.9700012,0-17.5799866-12.0999756-11.6300049-21.2700195l113.5299988-196.9899902,13.1400146-22.6900024,67.5900269-116.9299927,14.6500244,40.2700195-54.3500061,90.75-13.3299866,22.3099976-108.519989,181.4000244h300.2200012l5.5800171,23.25-.0900269-.0900269-.0100708-.0100098Z"
        />
        <path
            class="uuid-b5e5dd3a-ad35-4d35-8827-a31f0a141e9d"
            d="M610.4599731,754.5399933l-25.9000244,20.8900146-12.9500122-29.6799927-109.460022-297h470.2700195l-5.0100098-25.1400146h-510.7299805l124.7800293,339.6400146,18.0499878,47.2600098c3.4000244,8.8900146,14.460022,12,22.0200195,6.1400146l47.6400146-37.2399902,317.710022-257.7799683-6.6199951-33.75-329.7099609,266.5700073"
        />
        <path
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            d="M1070.6200073,377.6599884h40.0799561c30.8199463,0,55.8699951,25.1400146,55.8699951,55.8699951s-25.0500488,55.8699951-55.8699951,55.8699951h-34.3100586c-7,0-12.5699463,5.6699829-12.5699463,12.5700073s5.6700439,12.5700073,12.5699463,12.5700073h34.3100586c44.7099609,0,81.0999756-36.3900146,81.0999756-81.0100098s-36.3900146-81.0100098-81.0999756-81.0100098h-40.0799561v25.1400146Z"
        />
        <polygon
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            points="954.1600463 352.6100006 1070.6200073 352.6100006 1070.6200073 377.6599884 1069.3900268 377.6599884 1012.7700317 377.6599884 954.1600463 352.6100006"
        />
        <path
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            d="M954.1600463,352.6100006c23.0600586,0,43.8599854,9.6400146,58.6099854,25.1400146,13.9000244,14.5599976,22.4000244,34.2200012,22.4000244,55.8699951,0,34.980011-22.2099609,64.75-53.3100586,76.0900269-8.6999512,3.210022-17.9599609,4.9199829-27.6999512,4.9199829h-34.3099976c-3.3099976,0-6.2399902-1.2299805-8.5100098-3.3099976-2.5499878-2.2700195-4.1599731-5.5800171-4.1599731-9.2600098,0-6.9000244,5.6699829-12.5700073,12.5700073-12.5700073h34.3099976c8.1300049,0,15.7900391-1.7000122,22.7800293-4.8200073,19.4699707-8.7000122,33.0799561-28.3599854,33.0799561-50.9500122s-25.0500488-55.8699951-55.8699951-55.8699951h-40.0800171c-.4699707,0-1.5100098,0-2.1699829-.1900024-5.8599854-1.3200073-10.4000244-6.1400146-10.4000244-12.3800049s4.539978-11.3399963,10.4000244-12.3800049c.6599731-.0899963,1.4199829-.1900024,2.1699829-.1900024h40.0800171"
        />
        <path
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            d="M649.1200073,723.3399811l-82.8099976-208.1499634c-11.1500244-27.8900146-38.1900024-46.2200012-68.25-46.2200012h-28.3599854l111.0700073,309.5799866,68.3399658-55.2000122.0100098-.0100098Z"
        />
        <rect
            class="uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d"
            x="597.5100219"
            y="902.8499908"
            width="63.3300171"
            height="16.7299805"
            rx="7.75"
            ry="7.75"
        />
        <circle
            class="uuid-bb0621cb-5e3e-4487-b549-302cd99d1360"
            cx="580.7799804"
            cy="796.7000275"
            r="51.2299805"
        />
        <path
            class="uuid-bb0621cb-5e3e-4487-b549-302cd99d1360"
            d="M612.630017,902.0000153l-44.3300171-95.4700317c-2.9299927-6.2399902-.1900024-13.6099854,5.960022-16.539978h.2800293c6.3300171-3.1199951,13.7999878-.3800049,16.7299805,5.960022l48.8699951,105.960022h-27.5100098v.0900269-.000061Z"
        />
        <path
            class="uuid-bc0d907c-4f9a-497e-96de-925ff6da40fb"
            d="M576.8999755,800.5700226c0,2.0800171,1.7000122,3.8800049,3.8800049,3.8800049s3.8800049-1.7000122,3.8800049-3.8800049-1.7000122-3.8800049-3.8800049-3.8800049-3.8800049,1.7000122-3.8800049,3.8800049Z"
        />
        <g>
            <path
                class="uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88"
                d="M285.9199951,248.6699982c-31.8599854-39.1499939-46.4400024-77.2200012-46.4400024-122.5800018S256.4900024,33.75,286.4600036,0l12.9599915,13.5c-27.269989,33.75-40.769989,71.2799988-40.769989,111.7799988s14.5799866,78.8399963,40.769989,110.4300079l-13.5,12.9599915Z"
            />
            <path
                class="uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88"
                d="M352.0599792,85.0500031h71.0099792v19.4400024h-71.0100098v61.5599976h71.0100098v19.4400024h-93.6900024V5.4000015h93.6900024v19.4400024h-71.0100098v60.2099991h.0000305Z"
            />
            <path
                class="uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88"
                d="M502.4499633,129.3300018h-55.8900146v-18.6300049h55.8900146v18.6300049Z"
            />
            <path
                class="uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88"
                d="M525.3999755.0000038c32.1300049,39.4199944,46.4400024,77.2199974,46.4400024,122.579998s-17.0100098,92.0700073-46.9799805,126.0899963l-12.960022-13.5c27.2700195-33.75,40.7700195-71.2799988,40.7700195-111.7799988s-14.3099976-78.5699997-40.7700195-110.4300003c0,0,13.5-12.9599991,13.5-12.9599953Z"
            />
            <path
                class="uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88"
                d="M656.8899658,5.4000015c36.7200317,0,57.5100098,17.2799988,57.5100098,47.7900009,0,11.6100006-3.5100098,21.8700027-10.2600098,29.4300003-4.3200073,5.1299973-8.6400146,7.8299942-18.3599854,11.609993,9.9899902,2.1600037,14.8499756,4.0500031,20.5200195,8.3699951,9.7199707,7.8300018,14.8499756,19.9799957,14.8499756,34.5599976,0,16.7400055-7.289978,31.3200073-19.4400024,39.1499939-10.5300293,6.75-22.1400146,9.1799927-44.8200073,9.1799927h-50.7600098V5.4000015h50.7600098ZM628.8099487,86.4000015l22.9500122-.2699966c14.3099976,0,22.9500122-1.8899994,29.4299927-7.0199966,7.0200195-5.6699982,11.0700073-14.8499985,11.0700073-24.840004,0-11.6100006-5.6699829-21.8700027-14.3099976-25.9199982-6.4799805-3.2399979-14.039978-4.3199997-30.5100098-4.3199997h-18.6300049s0,62.3700027,0,62.3699951ZM628.8099487,167.1300049h22.6799927c18.9000244,0,26.1900024-1.3500061,34.0200195-5.9400024,8.6400146-4.8600006,12.960022-13.5,12.960022-25.3800049,0-21.6000061-12.6900024-31.0500031-41.3099976-31.0500031h-28.3499756v62.3699951l-.000061.0000153Z"
            />
            <path
                class="uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88"
                d="M776.4900024,26.1900024c0,7.2900009-5.9400024,12.9599991-13.2299805,12.9599991s-12.960022-5.6699982-12.960022-13.2300034,5.6699829-13.2299995,13.2299805-13.2299995,12.960022,5.9399986,12.960022,13.5000038ZM773.5200317,185.4900055h-20.5200195V61.0200043h20.5200195v124.4700012Z"
            />
            <path
                class="uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88"
                d="M835.3499877,185.4900055h-20.5200195V5.4000015h20.5200195v110.1600113l49.6799927-54.5400009h25.9199829l-55.6199951,60.7499924,55.3499756,63.7200012h-25.6500244l-49.6799927-55.6199951v55.6199951h.000061Z"
            />
            <path
                class="uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88"
                d="M939.839978,129.8700104c1.0799561,10.5299988,2.9699707,15.9299927,8.0999756,22.6799927,8.0999756,11.0700073,19.9799805,17.0099945,33.4799805,17.0099945,15.3900146,0,29.9699707-8.3699951,37.2600098-21.3300018l18.0899658,6.75c-10.2600098,20.7900085-31.5899658,33.4799957-55.3499756,33.4799957-36.9899902,0-63.4500122-27-63.4500122-64.8000031s26.1900024-65.6100006,62.6399536-65.6100006,63.1800537,27.2700043,63.1800537,63.7200012c0,1.6199951,0,5.1300049-.2700195,8.1000061h-103.6800537l.0001221.0000153ZM1021.919934,114.7500153c-.8100586-9.7200012-2.6999512-15.1199951-7.0200195-21.3300018-7.8299561-10.5299988-20.5200195-17.0100021-34.2900391-17.0100021s-26.1899414,6.4800034-33.4799805,17.2800064c-3.7800293,5.9400024-5.4000244,11.0700073-7.0200195,21.0599976h81.8100586Z"
            />
        </g>
    </svg>
</template>

<style type="text/css">
.uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d,
.uuid-bb0621cb-5e3e-4487-b549-302cd99d1360,
.uuid-b5e5dd3a-ad35-4d35-8827-a31f0a141e9d,
.uuid-bc0d907c-4f9a-497e-96de-925ff6da40fb {
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2.0999999px;
}

.uuid-90445deb-f89c-43dc-8f1f-32cd8d9f0f0d,
.uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88 {
    fill: #000;
}

.uuid-bb0621cb-5e3e-4487-b549-302cd99d1360,
.uuid-97cc222d-5dad-448a-acc4-f70d7c010341 {
    fill: #fff;
}

.uuid-b5e5dd3a-ad35-4d35-8827-a31f0a141e9d {
    fill: #ffcf1a;
}

.uuid-c326a6f4-ab78-4fd4-998e-a34772fc4a88,
.uuid-97cc222d-5dad-448a-acc4-f70d7c010341 {
    stroke-width: 0px;
}

.uuid-bc0d907c-4f9a-497e-96de-925ff6da40fb {
    fill: none;
}
</style>
