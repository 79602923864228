<template>
    <div class="visible md:hidden  bg-beige rounded-3xl">
        <agile
        :infinite="true"
        :autoplay="true"
        :navButtons="false"
        :speed="1000"
        :centerMode="true"
        :options="myOptions"
        :slidesToShow="1"
        :timing="'ease-in-out'"
        :dots="false"
    >
    
        <div v-for="(caption, index) in captions" :key="index" class="text-center flex flex-col items-center justify-between align-center w-full p-6">
            
            <img class="slide px-6 mx-auto object-contain 
                            
                            h-64 md:h-32 lg:h-40   w-full pb-4 lazy " :src="caption.image">
                       
          
       
           
           
                            <div>
                                <p class="pb-4 font-bradford font-semibold mt-0 ">{{caption.header}}</p>
                            </div>
                            
                            <a v-if="caption.render_link" :href="caption.render_link" :target="caption.render_link_target" class="w-full  text-center " >
                                <span v-if="caption.title"
                                    class="bg-primary font-semibold  p-2
                                      text-black hover:bg-black hover:text-white uppercase {data.call_to_action_button_size} w-10/12 rounded-full break-words mx-auto inline-block mt-2"
                                >
                                {{ caption.title }} 
                                </span>
                            </a>
                        </div>
        
        
    </agile>
    </div>
    

</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    name: 'DisturberCarousel',
    components: {
        agile: VueAgile,
    },
    data(){
        return {
            myOptions: {
            }
        }
    },
    props: {
        records: {
            type: String,
            default: '',
        },
      
    },
    computed: {
        captions() {
            return JSON.parse(this.records)
        },
    },
}
</script>

<style scoped>
.slide {
    /* max-height: 200px; */
    width: auto;
}

:global(.carousel-custom .agile__dot button){
    width: 10px;
    height: 10px;
    background-color:black;
    border-radius: 5px;
    margin: 10px 4px 0 2px;
}

:global(.carousel-custom .agile__dot--current button){
    background-color: #ffd400;
}
:global(.carousel-custom .agile__dot button:hover){
    background-color: #ffe97b;
}

</style>

