
@font-face {
    font-family: Neuzeit Grotesk;
    font-weight: 700;
    src: url('fonts/neuzeit/38B08A_0_0.eot');
    src: url('fonts/neuzeit/38B08A_0_0.eot?#iefix') format('embedded-opentype'),
        url('fonts/neuzeit/38B08A_0_0.woff2') format('woff2'),
        url('fonts/neuzeit/38B08A_0_0.woff') format('woff'),
        url('fonts/neuzeit/38B08A_0_0.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: Neuzeit Grotesk;
    font-weight: 400;
    src: url('fonts/neuzeit/38B08A_1_0.eot');
    src: url('fonts/neuzeit/38B08A_1_0.eot?#iefix') format('embedded-opentype'),
        url('fonts/neuzeit/38B08A_1_0.woff2') format('woff2'),
        url('fonts/neuzeit/38B08A_1_0.woff') format('woff'),
        url('fonts/neuzeit/38B08A_1_0.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: Bradford Medium;
    font-weight: 700;
    src: url('fonts/bradford/BradfordLLSub-Medium.eot');
    src: url('fonts/bradford/BradfordLLSub-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/bradford/BradfordLLSub-Medium.woff2') format('woff2'),
        url('fonts/bradford/BradfordLLSub-Medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: Bradford Medium Italic;
    font-weight: 400;
    src: url('fonts/bradford/BradfordLLSub-MediumItalic.eot');
    src: url('fonts/bradford/BradfordLLSub-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/bradford/BradfordLLSub-MediumItalic.woff2') format('woff2'),
        url('fonts/bradford/BradfordLLSub-MediumItalic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: Bradford Medium;
    font-weight: 400;
    src: url('fonts/bradford/BradfordLLWeb-Medium.eot');
    src: url('fonts/bradford/BradfordLLWeb-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/bradford/BradfordLLWeb-Medium.woff2') format('woff2'),
        url('fonts/bradford/BradfordLLWeb-Medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-display: swap;
    font-family: Bradford Medium Italic;
    font-weight: 400;
    src: url('fonts/bradford/BradfordLLWeb-MediumItalic.eot');
    src: url('fonts/bradford/BradfordLLWeb-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/bradford/BradfordLLWeb-MediumItalic.woff2') format('woff2'),
        url('fonts/bradford/BradfordLLWeb-MediumItalic.woff') format('woff');
    font-display: swap;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import 'css/base.css';
@import 'css/ce-faq.css';
@import 'css/ce-header.css';
@import 'css/calculator.css';
@import 'css/imageStacked.css';
@import 'css/ce-rte.css';
@import 'css/ce-desktop-nav.css';
@import 'css/career.css';
@import 'css/fadeContent.css';
