<template>
    <svg
        id="uuid-27e7681a-1b62-4635-956d-14ee19572925"
        data-name="Ebene 3"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1081.741856 1055.8399429"
        style="width: 100%; height: 100%"
    >
        <g>
            <g>
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M1057.4078475,791.3500137l-68.4100342-1.2999878-27.6800537-.5800171c-34.2099609,14.4899902-48.2600098,19.4199829-54.9300537,23.3300171h0c-1.0100098.4299927-2.1700439,1.0100098-3.4799805,1.4500122-.2900391.7199707-.4300537,1.2999878-.7199707,2.0300293-2.3199463,2.9000244-2.75,6.6699829-5.9399414,14.4899902-10.7299805,16.3800049-18.8399658,29.2800293-25.8000488,39.710022-21.3100586,31.8900146-30.4399414,39.710022-61.7399902,47.6799927-10.1500244,2.6099854-22.6099854,5.2199707-38.5499878,8.7000122l15.5100098-39.4199829c82.6100464-125.0800171,108.5599976-164.6499634,111.6000366-169,29.5699463-39.1300049,138.6999512,8.7000122,160.1600342,72.9000244l-.0200195.0098877Z"
                />
                <path
                    class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                    d="M989.137828,789.9000015c144.7900391,49.4199829,108.8499756,264.9399414-44.0600586,264.9399414-74.2099609,0-136.0999756-59.8599854-136.0999756-136.3900146v-.289978c31.3100586-7.9699707,40.4399414-15.9400024,61.7399902-47.6799927l1.0100098.5800171c-8.8399658,13.7700195-14.0600586,30.1500244-14.0600586,47.6799927,0,115.9499512,175.2299805,115.9499512,175.2299805,0,0-51.7399902-44.7900391-92.4699707-96.5300293-87.1099854-1.7399902-4.9299927-2.75-9.2800293-2.75-12.460022-9.7099609-17.0999756-2.9000244-16.6699829-26.8100586-6.2299805,6.6700439-3.9099731,20.5799561-8.8400269,54.9300537-23.3300171l27.3900146.289978h0l.0101318.000061Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M936.097911,831.4999771c11.8800049,33.9199829,57.8299561,100.4400024,51.7399902,109.5700073-3.1899414,4.9299927-10,6.2299805-15.6500244-1.8800049-3.6199951-5.3599854-8.9899902-3.7700195-17.9699707-3.3300171-10.4399414,0-17.3900146-10.5800171-13.3299561-20.1500244l4.6400146-11.0200195c-15.2199707-27.539978-10-20.5800171-15.9399414-20.5800171-6.5200195,0-11.8800049-7.9699707-5.2199707-14.7800293,5.5100098-5.7999878,5.8000488-1.7399902-3.0400391-18.5499878-3.9100342-7.0999756-8.9899902-9.5700073-13.1899414-11.4500122-7.25-3.1900024-12.1700439-4.6400146-5.6500244-23.039978,1.0100098-1.2999878,2.3199463-2.3200073,4.1999512-3.4799805h0c23.9100342-10.4400024,17.0999756-10.7299805,26.8100586,6.2299805-.1500244,3.3300171.8699951,7.539978,2.6099854,12.460022l-.0101318.000061Z"
                />
                <path
                    class="uuid-892a219f-d667-4ba5-8925-6507bf4ef37b"
                    d="M915.5178329,627.4300308c6.5200195,20.5800171,15.2199707,37.9699707-.1400146,55.5100098-11.7399902,13.4799805-15.9399414,17.3900146-17.5400391,21.0200195l-.5799561-.1400146c-16.6700439-33.6300049-25.8000488-56.0900269-50.4399414-84.3499756-82.7600098-93.6300049-94.5-93.0499878-61.5999756-125.9500122h0c19.5700073,5.0700073,45.5100098,40.1500244,130.3000488,133.9199829h0l-.0001221-.0100098Z"
                />
                <path
                    class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                    d="M902.177867,816.2800064c-6.5200195,18.2600098-1.4499512,19.8599854,5.6500244,23.039978-14.9300537,6.960022-27.5400391,18.1199951-36.5200195,31.7399902l-1.0100098-.5800171c6.9599609-10.4400024,15.0699463-23.3300171,25.8000488-39.710022,3.3299561-7.8300171,3.7700195-11.5900269,6.0899658-14.4899902l-.0100098.000061Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M897.2478133,703.6600113l.5799561.1400146c-1.3000488,3.039978-.5799561,5.9400024-.5799561,14.4899902-2.9000244,4.3499756-28.9899902,43.9199829-111.5999756,169-37.0999756,27.0999756-78.9899902,61.0200195-153.3399658,58.7000122-129.1400146-3.7700195-126.5300293-2.9000244-129.1400146-5.2199707,1.4500122-1.0100098,2.1699829-2.3200073,2.3200073-4.3499756.7199707-6.0900269-2.75-7.539978-15.3599854-7.6799927-47.6799927-43.7700195-68.4099731-57.9699707-66.3800049-60.7299805,2.3200073-3.1900024,35.9400024,9.2800293,94.5,8.8400269,155.5200195-1.0100098,182.6199951,6.960022,216.539978-28.4099731,44.9332886-46.7667236,67.3499756-70.2467041,67.25-70.4400024-32.3200073-54.5-30.289978-42.0299683-44.3499756-144.210022-2.75-20.1500244-6.0900269-44.6400146-10.4400024-74.9299927-5.0700073-19.4199829-14.2000122-41.1599731,7.3900146-55.2200317,12.75-8.4099731,20.8699951-12.9000244,30.4400024-10.289978h0c-32.7600098,32.7600098-21.0200195,32.3200073,61.5999756,125.9500122,24.7800293,28.2600098,34.0600586,50.8699951,50.5799561,84.3499756l-.0100098.0098877Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M783.7678939,464.5199966c32.3200073-14.2000122,26.0900269-14.4899902,28.4099731,2.6099854,6.5200195,26.5200195,103.1999512,119.2800293,103.1999512,160.2999878-84.789978-93.6300049-110.5899658-128.7000122-130.2999878-133.9199829-9.5700073-2.6099854-17.6799927,2.0300293-30.4400024,10.289978-21.5999756,14.0599976-12.460022,35.9400024-7.3900146,55.3699951,4.3499756,30.289978,7.6799927,54.789978,10.4400024,74.9299927l-9.1300049,1.2999878c-3.9099731-29.1300049-13.3300171-69.710022-38.5499878-139.1400146l53.1900024-22.7600098h0c2.9000244-.8699951,6.960022-2.75,13.3300171-5.6500244,0,0,.1400146,0,.1400146-.1400146.289978-.1400146.5800171-.289978,1.0100098-.4299927l6.0900269-2.75v-.0098877Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M842.0278426,358.7099991l-21.5999756,80.0100098-28.8400269-1.1600037c0-6.9599915-2.460022-1.5899963,14.6400146-28.1199951l.1400146-1.4500122c2.0300293-18.9899902,7.3900146-13.1900024-27.25-24.6399841-18.4099731-6.0899963-31.5999756-6.3800049-42.0299683-3.1900024,0-.7200012-.1400146-.5799866,9.2800293-14.6400146,2.1699829-3.1900024,4.7800293-7.1000061,7.9699707-11.8800049,4.3499756-6.3800049,9.710022-14.4899902,16.3800049-24.4899902l71.3100586,29.5700073-.0001221-.0100098Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M779.2778426,383.3500137c34.7800293,11.3099976,29.4199829,5.5100098,27.25,24.6400146l-.1400146,1.4500122c-17.0999756,26.519989-14.7800293,21.1600037-14.6400146,28.1199951.289978,24.3500061.5800171,23.1900024,0,23.3299866-5.6500244,2.3200073-10.1500244,4.4899902-14.0599976,6.230011l-1.0100098.4299927s-.1400146,0-.1400146.1400146l-13.3300171,5.6500244h0c-7.3900146,2.1699829-6.960022-2.9000244-16.0900269-17.5400085-3.6199951-5.6499939,2.9000244-4.2000122-37.0999756-4.2000122l-23.039978-7.9700012c34.6400146-7.3900146,42.4699707-7.9700012,42.4699707-21.8900146s-10.1500244-14.4899902-27.6799927-10.5799866c10-12.0299988,18.8400269-25.9400024,35.6500244-31.019989,10.1500244-3.1900024,23.4799805-2.8999939,41.8900146,3.1900024l-.0299683.0199585Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M802.0278426,778.1600113c.289978.5800171.289978.289978-67.25,70.4400024-34.0599976,35.3599854-61.0200195,27.3900146-216.539978,28.4099731-58.5499878.4299927-92.1799927-12.1699829-94.5-8.8400269l-.4299927-.7199707,24.0599976-49.4199829,25.5100098-52.6099854c26.8099976-55.3699951-5.3599854-120.7299805-64.5-133.9199829,154.0700073,26.0900269,153.4899902,26.0900269,154.6499634,25.2199707l-12.3200073,72.4700317c-6.8099976,40.289978,22.4699707,77.6900024,63.1900024,80.8699951,106.5300293,6.0900269,95.0800171,14.9299927,150.4400024-63.0499878-16.6699829-50.1500244-10-67.6900024-15.7999878-111.75l9.1300049-1.2999878c14.0599976,102.1799927,12.1699829,89.7199707,44.3499756,144.210022l.0100098-.0100708Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M763.1878768,473.2099991l-53.1900024,22.7600098c-1.1599731.5800171-.8699951,5.3599854,0-44.5,40,0,33.4799805-1.4500122,37.0999756,4.2000122,9.1300049,14.6400146,8.7000122,19.710022,16.0900269,17.539978Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M670.2778426,283.4899979c12.460022,3.3299866,24.3499756,16.6700134,84.210022,70-3.1900024,4.7799988-5.7999878,8.7000122-7.9699707,11.8800049l-.289978-.1400146c-77.6900024-69.4200134-75.0800171-68.269989-78.5599976-68.269989-12.460022-.2900085-23.3300171,1.5899963-23.3300171-7.3900146,0-3.6199951,3.039978-6.9599915,6.960022-6.9599915,8.7000122,0,13.9099731-.4299927,18.9899902.8699951l-.0100708.0100098Z"
                />
                <path
                    class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                    d="M701.5878402,411.1800003c17.539978-3.9100037,27.6799927-3.1900024,27.6799927,10.5799866s-7.8300171,14.6400146-42.4699707,21.8900146c-4.0599976.8699951-8.5499878,1.8800049-13.4799805,3.0400085,12.3200073-6.3800049,6.2299805-30.2900085-11.3099976-24.6399841v-.2900085c15.5100098-4.2000122,28.9899902-8.2600098,39.5700073-10.5799866l.0099487-.0000305Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M673.3278915,446.5400162c-1.8800049,1.0100098-4.0599976,1.5899963-6.8099976,1.5899963-15.0700073,0-18.8400269-21.1600037-4.4899902-26.230011,17.6799927-5.6499939,23.6199951,18.2600098,11.3099976,24.6400146h-.0100098Z"
                />
                <path
                    class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                    d="M623.4678451,282.3299942c11.5900269-44.3500061,50.8699951-42.6099854,46.6699829,1.2999878-5.0700073-1.2999878-10.289978-1.0100098-18.9899902-1.0100098-3.9099731,0-6.960022,3.3299866-6.960022,6.9599915,0,8.9899902,10.8699951,7.1000061,23.3300171,7.3900146-14.7800293,47.6799927-56.6699829,33.769989-44.0599976-14.6400146l.0100098.0000305Z"
                />
                <path
                    class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                    d="M408.2378646,631.340004c-16.960022-2.9000244-35.7999878-6.0900269-56.8200073-9.5700073l-9.8599854-1.5900269c-17.0999756-2.9000244-35.3599854-5.9400024-55.0800171-9.2800293-5.2200012-.8699951-10.730011-1.7399902-16.230011-2.75-45.5100098-7.6799927-41.3099976-8.2600098-72.1799927-7.6799927-9.5700073.1400146-22.6100159.4299927-41.4500122.5800171,17.25-36.0900269,65.0799866-59.4199829,106.3800049-62.0299683,88.9900208-5.5100098,100.730011-8.1199951,127.5400085-1.8800049,66.6699829,15.5100098,72.6099854,17.6799927,91.8900146,18.1199951,62.6099854,1.5900269,126.2399902,67.6900024,80.5800171,101.3099976-1.1599731.8699951-.5800171.8699951-154.6499634-25.2199707h-.1400146l.0199585-.0100098Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M272.8678695,898.7499771c14.2000122,0,30.5799866.1400146,49.2799988.289978,15.3600159,0,32.4700012.1400146,51.3100281.1400146,24.7800293,0,38.5499878,6.2299805,77.9799805,18.8400269,4.9299927,1.5900269,8.1199951,5.9400024,8.1199951,11.1599731,13.6199951,0,23.4799805-.289978,30.5800171-.1400146,12.6099854.1400146,16.2299805,1.4500122,15.3599854,7.6799927-.1400146,2.0300293-.8699951,3.3300171-2.3200073,4.3499756-4.4899902,2.9000244-16.0900269,1.5900269-41.5999756,1.5900269-64.6400146,10.4400024-44.3499756,10.7299805-142.4700012,10.4400024-14.2000122,0-31.019989-.1400146-50.8699951-.1400146s-44.5-.1400146-72.4700012-.1400146c-13.9100037,0-33.1900024-19.4199829-26.230011-36.5200195,6.9599915-16.960022,12.4599915-17.9699707,103.3399963-17.539978l-.0100098-.0099487Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M408.3778792,631.340004c59.1300049,13.1900024,91.3099976,78.5599976,64.5,133.9199829l-25.5100098,52.6099854c-11.7399902-2.6099854-15.9400024-4.7800293-19.710022-3.3300171l-.289978-1.0100098c15.7999878-34.7799683,32.0299683-59.2799683,17.3900146-95.3699951-21.5999756-53.1900024-100.4400024-66.3800049-157.8399963-66.8200073-98.6999817,0-161.4599915,46.5200195-221.6100006,123.3399658l-.1399994.8699951c-17.9700012-3.1900024-30.4400024-5.3599854-39.1300049-6.5200195,84.5-128.4100342,71.4499817-117.25,172.0400085-168.5599976v-.1400146c30.8699951-.5800171,26.6700134.1400146,72.1799927,7.6799927,5.5100098.8699951,10.8699951,1.8800049,16.230011,2.75,19.7099915,3.3300171,38.1200256,6.3800049,55.0799866,9.2800293,3.3300171.5800171,6.5200195,1.1599731,9.8599854,1.5900269,20.8699951,3.6199951,39.710022,6.6699829,56.8200073,9.5700073,0,.0933228.0466919.1400146.1400146.1400146l-.0100098.000061Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M300.6878768,843.090004c-33.3399963-6.5200195-63.0499878-12.3200073-89.5700073-17.539978-40.4400024-7.9699707-73.3399963-14.3499756-100.0100098-19.5700073-119.7200012-23.7700195-114.3600006-24.6400146-106.6699982-35.3599854,2.0299988-2.75,2.75-4.3499756,21.6000061-1.5900269,8.6999969,1.2999878,21.0200043,3.3300171,39.1300049,6.5200195,52.030014,9.2800293,150.8799896,28.1199951,340.7499847,62.7600098,13.7700195-15.9400024,17.539978-22.1799927,21.7399902-23.7700195,3.7700195-1.4500122,7.9699707.8699951,19.710022,3.3300171l-24.0599976,49.5700073c-45.5100098-9.1300049-86.2399902-17.25-122.6199951-24.3499756v-.000061Z"
                />
                <path
                    class="uuid-11a6161b-9674-41a2-b9d7-11843758265f"
                    d="M444.7578841,718.1600113c14.6400146,36.0900269-1.5900269,60.4400024-17.3900146,95.3699951l.289978,1.0100098c-4.3499756,1.5900269-7.9699707,7.8300171-21.7399902,23.7700195-189.8699951-34.6400146-288.7099915-53.4799805-340.75-62.7600098l.1399994-.8699951c60.1500092-76.8200073,122.9099884-123.3399658,221.6100006-123.3399658,57.3900146.5800171,136.2399902,13.6199951,157.8399658,66.8200073l.000061-.000061ZM330.1078597,738.0199966h-32.3200073l23.3299866-43.6300049h-46.519989l-14.4899902,43.6300049h22.0299988c-19.4200134,65.9500122-29.8600159,61.4500122,47.9700012,0Z"
                />
                <path
                    class="uuid-af339713-acaf-47a7-a1b5-0acc483778b6"
                    d="M297.6478683,738.0099869h32.3199768c-77.8300171,61.4500122-67.3999939,65.9500122-47.9700012,0h-22.0299988l14.4899902-43.6300049h46.519989l-23.3299866,43.6300049h.0000305Z"
                />
                <path
                    class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                    d="M300.6878768,843.090004c11.019989,16.5200195,18.5499878,35.3599854,21.4500122,55.7999878-18.7000122,0-35.0700073-.1400146-49.2799988-.289978-8.7000122-37.539978-34.2099915-58.5499878-63.1900024-65.0800171l1.5899963-8.1199951c26.3800049,5.3599854,56.0899963,11.1599731,89.4299927,17.6799927v.0100098Z"
                />
                <path
                    class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                    d="M268.3678695,952.6600113c19.8599854,0,36.6699829.1400146,51.019989.1400146-39.5700073,154.0699463-267.8399963,125.2299805-267.8399963-34.0599976,0-46.8099976,23.6199951-88.2700195,59.7099915-112.7600098,26.6700134,5.2199707,59.5700073,11.7399902,100.0100098,19.5700073l-1.5899963,8.1199951c-53.0499878-12.0300293-117.3999939,24.3499756-109.1400146,95.5100098,10.1499939,88.6999512,132.9100037,106.5300293,167.8399963,23.4799805h-.0099792Z"
                />
            </g>
            <g>
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M667.6678573,296.9700089c-14.7800293,47.8300171-56.8200073,33.769989-44.0599976-14.6400146,11.5900269-44.3500061,50.8699951-42.6099854,46.6699829,1.2999878h0"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M268.3678695,952.5199966h0c-34.9299927,83.1900635-157.5499878,65.3699951-167.8399963-23.3300171-8.2600098-71.0200195,56.0899963-107.4000244,109.1399841-95.5100098,28.9899902,6.5200195,54.5,27.539978,63.1900024,65.0800171,0,.1400146,0,.289978.1400146.289978"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M300.5478622,842.9499893l.1400146.1400146c11.019989,16.5200195,18.5499878,35.3599854,21.4500122,55.7999878v.1400146"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M319.3878585,952.5199966s0,.1400146-.1400146.289978c-39.5700073,154.0700073-267.8399963,125.0800171-267.8399963-34.0599976,0-46.8099976,23.6199951-88.2700195,59.7099915-112.7600098.2900085-.1400146.5799866-.4299927.8699951-.5800171"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M26.0378677,769.0300064c84.4999847-128.4100342,71.4499969-117.25,172.0399933-168.5599976"
                />
                <polyline
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    points="407.6578475 631.1899796 351.4178573 621.7699966 341.5678817 620.1800308"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M408.2378646,631.340004c-.1400146,0-.289978,0-.4299927-.1400146"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M447.3678695,818.0199966l25.5100098-52.6099854c26.8099976-55.3699951-5.3599854-120.7299805-64.5-133.9199829"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M198.0778609,600.3199844c30.8699951-.5800171,26.6700134.1400146,72.1799927,7.6799927,5.5100098.8699951,10.8699951,1.8800049,16.230011,2.75,19.7099915,3.3300171,38.1199951,6.3800049,55.0800171,9.2800293,3.3300171.5800171,6.5200195,1.1599731,9.8599854,1.5900269,20.8699951,3.6199951,39.710022,6.6699829,56.8200073,9.5700073h.1400146c154.0700073,26.0900269,153.4899902,26.0900269,154.6499634,25.2199707,45.7999878-33.6300049-17.9699707-99.8599854-80.5800171-101.3099976-19.1300049-.4299927-25.0700073-2.6099854-91.8900146-18.1199951-26.8099976-6.2299805-38.5499878-3.6199951-127.5400085,1.8800049-41.3099976,2.6099854-89.1399841,25.9400024-106.3800049,62.0299683,18.9899902-.1400146,31.8899841-.4299927,41.4500122-.5800171l-.0199585.0100098Z"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M709.9978744,495.9700089c25.2199707,69.4199829,34.7799683,110.0100098,38.5499878,139.1400146,5.7999878,44.0599976-.8699951,61.5999756,15.7999878,111.75-55.3699951,77.9799805-44.0599976,69.1300049-150.5900269,63.0499878-40.7299805-3.1900024-70-40.5800171-63.1900024-80.8699951l12.3200073-72.4699707"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M897.2478133,718.3000259c0-8.5499878-.7199707-11.4500122.5799561-14.4899902,1.4499512-3.6199951,5.8000488-7.539978,17.5400391-21.0200195,15.2199707-17.539978,6.6700439-34.9299927.1400146-55.5100098h0c-.1400146-41.0200195-96.6699829-133.6300049-103.2000122-160.2999878-2.460022-17.1000061,3.9099731-16.6700134-28.4099731-2.6099854"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M503.3178817,940.7800064c2.6099854,2.3200073,0,1.4500122,129.1400146,5.2199707,74.210022,2.1699829,116.0899658-31.5999756,153.3399658-58.7000122"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M915.9478866,628.0099869c-.1400146-.1400146-.4300537-.4299927-.5799561-.5800171-84.789978-93.6300049-110.5899658-128.7000122-130.2999878-133.9199829-9.5700073-2.6099854-17.6799927,2.0300293-30.4400024,10.289978-21.5999756,14.0599976-12.460022,35.9400024-7.3900146,55.3699951,4.3499756,30.289978,7.6799927,54.789978,10.4400024,74.9299927,14.0599976,102.3300171,12.0300293,89.7199707,44.3499756,144.210022.289978.5800171.289978.289978-67.25,70.4400024-34.0599976,35.3599854-61.0200195,27.3900146-216.539978,28.4099731-58.5499878.4299927-92.1799927-12.1699829-94.5-8.8400269-2.0300293,2.75,18.8400269,16.960022,66.3800049,60.7299805,0,0,.1400146,0,.1400146.1400146"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M897.2478133,703.6600113c-16.6700439-33.6300049-25.8000488-56.0900269-50.4399414-84.3499756-82.7600098-93.6300049-94.5-93.0499878-61.5999756-125.9500122"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M934.6478377,831.6399918c.4300537,0,1.0100098-.1400146,1.4499512-.1400146,51.7399902-5.3599854,96.5300293,35.3599854,96.5300293,87.1099854,0,115.9499512-175.2299805,115.9499512-175.2299805,0,0-17.539978,5.2199707-33.9199829,14.0600586-47.6799927,8.8399658-13.7700195,21.5999756-24.7800293,36.5200195-31.7399902"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M989.137828,789.9000015c144.7900391,49.4199829,108.8499756,264.9399414-44.0600586,264.9399414-74.2099609,0-136.0999756-59.8599854-136.0999756-136.3900146"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M754.4878646,353.5000076c-59.8599854-53.1900024-71.7399902-66.6700134-84.210022-70-5.0700073-1.2999878-10.289978-1.0100098-18.9899902-1.0100098-3.9099731,0-6.960022,3.3299866-6.960022,6.9599915,0,8.9899902,10.8699951,7.1000061,23.3300171,7.3900146,3.4799805,0,1.0100098-1.0100098,78.5599976,68.269989"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M906.5278426,812.8000259c6.6700439-3.9099731,20.5799561-8.8400269,54.9300537-23.3300171l27.6800537.5800171,68.4100342,1.2999878c-21.4499512-64.0599976-130.7299805-112.039978-160.1600342-72.9000244-2.9000244,4.3499756-28.9899902,43.9199829-111.5999756,169l-15.5100098,39.4199829c15.9400024-3.4799805,28.4099731-6.0900269,38.5499878-8.7000122,31.3099976-7.9699707,40.4400024-15.9400024,61.7400513-47.6799927,6.9599609-10.4400024,15.0699463-23.3300171,25.8000488-39.710022,3.1899414-7.6799927,3.6199951-11.5900269,5.9399414-14.4899902.8699951-1.2999878,2.1700439-2.3200073,4.1999512-3.4799805"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M902.177867,816.2800064c.2900391-.7199707.4300537-1.2999878.7199707-2.0300293,1.1600342-.5800171,2.4599609-1.0100098,3.4799805-1.4500122h0c23.9100342-10.4400024,17.0999756-10.7299805,26.8100586,6.2299805,0,3.3300171,1.0100098,7.539978,2.75,12.460022,11.8800049,33.9199829,57.8299561,100.4400024,51.7399902,109.5700073-3.1899414,4.9299927-10,6.2299805-15.6500244-1.8800049-3.6199951-5.3599854-8.9899902-3.7700195-17.9699707-3.3300171-10.4399414,0-17.3900146-10.5800171-13.3299561-20.1500244l4.6400146-11.0200195c-15.2199707-27.539978-10-20.5800171-15.9399414-20.5800171-6.5200195,0-11.8800049-7.9699707-5.2199707-14.7800293,5.5100098-5.7999878,5.8000488-1.7399902-3.0400391-18.5499878-3.9100342-7.0999756-8.9899902-9.5700073-13.1899414-11.4500122-7.25-3.3300171-12.3199463-4.7800293-5.8000488-23.039978l-.0001221.0001221Z"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M503.3178817,940.7800064c-4.4899902,2.9000244-16.0900269,1.5900269-41.5999756,1.5900269-64.6400146,10.4400024-44.3499756,10.7299805-142.4700012,10.4400024-14.2000122,0-31.019989-.1400146-50.8699951-.1400146s-44.5-.1400146-72.4700012-.1400146c-13.9100037,0-33.1900024-19.4199829-26.230011-36.5200195,6.6700134-16.6699829,12.3200073-17.539978,103.1999817-17.25,14.2000122,0,30.5799866.1400146,49.2799988.289978,15.3600159,0,32.4700012.1400146,51.3100281.1400146,24.7800293,0,38.5499878,6.2299805,77.9799805,18.8400269,4.9299927,1.5900269,8.1199951,5.9400024,8.1199951,11.1599731,13.6199951,0,23.4799805-.289978,30.5800171-.1400146,12.6099854.1400146,16.2299805,1.4500122,15.3599854,7.6799927,0,1.7399902-.7199707,3.1900024-2.1699829,4.0599976l-.0200195-.0099487Z"
                />
                <polyline
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    points="776.8078719 467.5599747 777.677867 467.129982 783.7678939 464.5199966 792.1678573 460.8899918"
                />
                <line
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    x1="763.1878768"
                    y1="473.2099991"
                    x2="776.5178939"
                    y2="467.5599747"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M709.9978744,451.4700089c-.8699951,49.8599854-1.1599731,45.0800171,0,44.5l53.1900024-22.7600098"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M737.2378646,380.0199966c0-.7200012-.1400146-.5799866,9.2800293-14.6400146,2.1699829-3.1900024,4.7800293-7.1000061,7.9699707-11.8800049,4.3499756-6.3800049,9.710022-14.4899902,16.3800049-24.4899902l71.1599731,29.7099915-21.5999756,80.0100098-28.8400269-1.1600037"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M806.3778792,409.4400101c-17.0999756,26.519989-14.7800293,21.1600037-14.6400146,28.1199951.289978,24.3500061.5800171,23.1900024,0,23.3299866-5.6500244,2.3200073-10.1500244,4.4899902-14.0599976,6.2299805-.289978.1400146-.7199707.289978-1.0100098.4299927,0,0-.1400146,0-.1400146.1400146-6.3800049,2.9000244-10.4400024,4.7800293-13.3300171,5.6500244h0c-7.3900146,2.1699829-6.960022-2.9000244-16.0900269-17.5400085-3.6199951-5.6499939,2.9000244-4.2000122-37.0999756-4.2000122l-23.039978-7.9700012-.1400146-.1400146"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M701.2978622,411.4700089c.1400146-.1400146.289978-.2900085.289978-.4299927,10-12.0299988,18.8400269-25.9400024,35.6500244-31.019989,10.289978-3.1900024,23.6199951-2.8999939,42.0299683,3.1900024,34.7800293,11.3099976,29.4199829,5.5100098,27.25,24.6399841"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M672.3078719,446.8299942c.4299927-.1400146.7199707-.1400146,1.0100098-.2900085,4.9299927-1.1600037,9.4199829-2.0299988,13.4799805-3.0400085,34.6400146-7.3900146,42.4699707-7.9700012,42.4699707-21.8900146s-10.1500244-14.4899902-27.6799927-10.5799866c-10.5800171,2.4599915-24.0599976,6.519989-39.5700073,10.5799866v.2900085"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M662.0178939,421.9100113c17.539978-5.6499939,23.6199951,18.2600098,11.3099976,24.6400146-1.8800049,1.0100098-4.0599976,1.5899963-6.8099976,1.5899963-15.0700073,0-18.8400269-21.1600037-4.4899902-26.230011h-.0100098Z"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M65.3178664,774.8299942c60.1500092-76.8200073,122.9099884-123.3400269,221.6100006-123.3400269,57.4000244.4299927,136.2399902,13.6199951,157.8399658,66.8200073,14.6400146,36.0900269-1.5900269,60.4400024-17.3900146,95.3699951"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M26.0378677,769.0300064c-18.8399963-2.75-19.5699921-1.2999878-21.6000061,1.5900269-7.6799927,10.8699951-13.0399933,11.7399902,106.6699982,35.3599854,26.6700134,5.2199707,59.5700073,11.7399902,100.0100098,19.5700073,26.519989,5.2199707,56.2399902,11.0200195,89.5700073,17.539978,36.3800049,7.0999756,77.1099854,15.2199707,122.6199951,24.2000122l24.0599976-49.4199829c-11.7399902-2.6099854-15.9400024-4.7800293-19.710022-3.3300171-4.3499756,1.5900269-7.9699707,7.8300171-21.7399902,23.7700195-190.0100098-34.6400146-288.7099915-53.4799805-340.75-62.7600098-18.1199951-3.1900024-30.4400024-5.3599854-39.1300049-6.5200195h.0000153Z"
                />
                <path
                    class="uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e"
                    d="M282.1378585,738.0099869h-22.0299988l14.4899902-43.6300049h46.519989l-23.3299866,43.6300049h32.3200073c-77.8300171,61.4500122-67.3999939,65.9500122-47.9700012,0Z"
                />
            </g>
        </g>
        <g>
            <path
                class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                d="M174.3978683,79.6500015h71.0100098v19.4400024h-71.0100098v61.5599976h71.0100098v19.4400024h-93.6900024V0h93.6900024v19.4400024h-71.0100098v60.2099991Z"
            />
            <path
                class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                d="M324.7778426,123.9300003h-55.8900146v-18.6300049h55.8900146v18.6300049Z"
            />
            <path
                class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                d="M369.8678695,180.090004h-22.6799927V0h49.1400146c38.3400269,0,61.5599976,19.7099991,61.5599976,52.3799973,0,20.25-9.1799927,36.4499969-25.9199829,45.3600006-8.6400146,4.5899963-16.7399902,6.4799957-33.210022,7.5599976l69.9299927,74.7900085h-28.8900146l-69.9299927-75.6000061v75.6000061ZM392.5478622,89.1000137c27.2700195,0,42.1199951-12.4199982,42.1199951-35.0999985s-13.5-34.5600052-38.3400269-34.5600052h-26.460022v69.6600037h22.6800537Z"
            />
            <path
                class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                d="M606.927867,118.2600021c0,36.4499969-28.0800171,64.8000031-63.7199707,64.8000031s-62.9099731-27-62.9099731-65.3399963,25.9199829-65.0699997,63.7200317-65.0699997,62.9099731,27.2699966,62.9099731,65.6100082l-.000061-.0000153ZM501.3578597,117.9899979c0,27.5400085,17.0100098,46.1699982,42.3900146,46.1699982s42.1199951-19.1699982,42.1199951-46.1699982-16.7399902-46.4400024-41.8499756-46.4400024-42.6599731,18.6300049-42.6599731,46.4400024h-.000061Z"
            />
            <path
                class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                d="M638.2478744,180.090004V0h20.5200195v180.090004h-20.5200195Z"
            />
            <path
                class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                d="M700.0678817,180.090004V0h20.5200195v180.090004h-20.5200195Z"
            />
            <path
                class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                d="M773.7778426,124.4700089c1.0800171,10.5299988,2.9699707,15.9299927,8.0999756,22.6799927,8.0999756,11.0700073,19.9799805,17.0099945,33.4799805,17.0099945,15.3900146,0,29.9699707-8.3699951,37.2600098-21.3300018l18.0899658,6.75c-10.2600098,20.7900085-31.5899658,33.4799957-55.3499756,33.4799957-36.9899902,0-63.4500122-27-63.4500122-64.8000031s26.1900024-65.6100006,62.6400146-65.6100006,63.1799927,27.2700043,63.1799927,63.7200012c0,1.6199951,0,5.1300049-.2700195,8.1000061h-103.6799927l.000061.0000153ZM855.8577987,109.3500137c-.8100586-9.7200012-2.6999512-15.1199951-7.0200195-21.3300018-7.8299561-10.5299988-20.5200195-17.0100021-34.289978-17.0100021s-26.1900024,6.4800034-33.4799805,17.2800064c-3.7800293,5.9400024-5.4000244,11.0700073-7.0200195,21.0599976h81.8099976Z"
            />
            <path
                class="uuid-9254e05e-82a6-44f3-8766-99eff876a86c"
                d="M927.9478866,55.6200027v21.0599976c3.7800293-8.9100037,6.4799805-12.6900024,11.3399658-16.4700012,6.2099609-4.8600006,14.8499756-7.5599976,23.7600098-7.5599976h4.8599854v20.7900009c-2.1600342-.2699966-4.3199463-.2699966-5.6700439-.2699966-11.0699463,0-20.7900391,5.1299973-27,14.3099976-5.4000244,7.8300018-7.2900391,15.1199951-7.2900391,29.4300079v63.1799927h-20.5200195V55.6200027h20.5201416Z"
            />
        </g>
    </svg>
</template>

<style type="text/css">
.uuid-11a6161b-9674-41a2-b9d7-11843758265f {
    fill: #ffcf1a;
}

.uuid-11a6161b-9674-41a2-b9d7-11843758265f,
.uuid-9254e05e-82a6-44f3-8766-99eff876a86c,
.uuid-892a219f-d667-4ba5-8925-6507bf4ef37b,
.uuid-af339713-acaf-47a7-a1b5-0acc483778b6 {
    stroke-width: 0px;
}

.uuid-ce98cc35-73dc-4bb9-b728-3ef038df647e {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}

.uuid-9254e05e-82a6-44f3-8766-99eff876a86c {
    fill: #000;
}

.uuid-892a219f-d667-4ba5-8925-6507bf4ef37b {
    fill: #fbfbfb;
}

.uuid-af339713-acaf-47a7-a1b5-0acc483778b6 {
    fill: #fff;
}
</style>
