:root{
    --calcColorButton: #ffd400;
    --calcColorText: black;
}

.radio-toolbar {
    margin-right: 10px
}

.radio-toolbar input[type=radio] {
    opacity: 0;
    position: fixed;
    width: 0
}

.radio-toolbar label {
    display: inline-block;
    background-color: #ddd;
    padding: 5px 15px;
    font-size: 16px;
    border-radius: 9999px;
    line-height: 1.5;
}

.radio-toolbar-number input[type="number"]:disabled {
    background-color: white;
    color: #a6a6a6;
    cursor: not-allowed;
}

.checkbox-toolbar {
    margin-right: 10px
}

.checkbox-toolbar input[type=checkbox] {
    opacity: 0;
    position: fixed;
    width: 0
}

.checkbox-toolbar label {
    display: inline-block;
    background-color: #ddd;
    padding: 5px 15px;
    font-size: 16px;
    border-radius: 9999px;
    content: "X";
}

.calculator-success{
    background-color: #EDFFF3;
    border: #46D390;
    color:#008A48;
}

/* Color Options */

/* Yellow */
.radio-toolbar-yellow label:hover {
    background-color: #ffd400;
    opacity: 0.6;
}

.radio-toolbar-yellow input[type=radio]:checked+label {
    background-color: #ffd400;
}
.radio-toolbar-yellow input[type=radio]:not(:checked):disabled+label {
    background-color: #ddd;
    opacity: 0.4;
    color: #8c8c8c;
    cursor: not-allowed;
}

.radio-toolbar-yellow input[type=radio]:checked:disabled+label {
    background-color: #ffd400;
    opacity: 0.4;
    color: #8c8c8c;
    cursor: not-allowed;
}

.checkbox-toolbar-yellow label:hover {
    background-color: #ffd400;
    opacity: 0.6;
}

.checkbox-toolbar-yellow input[type=checkbox]:checked+label {
    background-color: #ffd400;
}

.checkbox-toolbar-yellow input[type=checkbox]:checked:disabled+label {
    background-color: #ffd400;
    opacity: 0.4;
    color: #8c8c8c;
    cursor: not-allowed;
}

.checkbox-toolbar-yellow input[type=checkbox]:not(:checked):disabled+label {
    background-color: #ddd;
    opacity: 0.4;
    color: #8c8c8c;
    cursor: not-allowed;
}

.calculator-yellow {
    color: #ffd400;
}

/* Blue */
.radio-toolbar-blue label:hover {
    background-color: #0400CE;
    opacity: 0.6;
    color: white;
}

.radio-toolbar-blue input[type=radio]:checked+label {
    background-color: #0400CE;
    color: white;
}

.radio-toolbar-blue input[type=radio]:not(:checked):disabled+label {
    background-color: #ddd;
    opacity: 0.4;
    color: #a6a6a6;
    cursor: not-allowed;
}

.radio-toolbar-blue input[type=radio]:checked:disabled+label {
    background-color: #0400CE;
    opacity: 0.4;
    color: #f2f2f2;
    cursor: not-allowed;
}

.checkbox-toolbar-blue label:hover {
    background-color: #0400CE;
    opacity: 0.6;
    color: white;
}

.checkbox-toolbar-blue input[type=checkbox]:checked+label {
    background-color: #0400CE;
    color: white;
}
.checkbox-toolbar-blue input[type=checkbox]:checked:disabled+label {
    background-color: #0400CE;
    opacity: 0.4;
    color: #f2f2f2;
    cursor: not-allowed;
}

.checkbox-toolbar-blue input[type=checkbox]:not(:checked):disabled+label {
    background-color: #ddd;
    opacity: 0.4;
    color: #a6a6a6;
    cursor: not-allowed;
}

.calculator-blue {
    color: #0400CE;
}

/* Custom */
.radio-toolbar-custom label:hover {
    background-color: var(--calcColorButton);
    opacity: 0.6;
    color: var(--calcColorText);
}

.radio-toolbar-custom input[type=radio]:checked+label {
    background-color: var(--calcColorButton);
    color: var(--calcColorText);
}

.radio-toolbar-custom input[type=radio]:not(:checked):disabled+label {
    background-color: #ddd;
    opacity: 0.4;
    color: #a6a6a6;
    cursor: not-allowed;
}

.radio-toolbar-custom input[type=radio]:checked:disabled+label {
    background-color: var(--calcColorButton);
    opacity: 0.4;
    color: var(--calcColorText);
    cursor: not-allowed;
}

.checkbox-toolbar-custom label:hover {
    background-color: var(--calcColorButton);
    color: var(--calcColorText);
    opacity: 0.6;
}

.checkbox-toolbar-custom input[type=checkbox]:checked+label {
    background-color: var(--calcColorButton);
    color: var(--calcColorText);
}
.checkbox-toolbar-custom input[type=checkbox]:checked:disabled+label {
    background-color: var(--calcColorButton);
    opacity: 0.4;
    color: #f2f2f2;
    cursor: not-allowed;
}

.checkbox-toolbar-custom input[type=checkbox]:not(:checked):disabled+label {
    background-color: #ddd;
    opacity: 0.4;
    color: #a6a6a6;
    cursor: not-allowed;
}

.calculator-custom {
    color: var(--calcColorButton);
}
.h-18 {
    height: 4.5rem;
}
