.ThreeItems {
    /* height: 220px; */
    width: 100%;
    object-fit: cover;
    object-position: inherit;
}

@media (min-width: 1280px) {
    .ThreeItems {
        height: 270px;
        object-fit: cover;
        object-position: inherit;
    }
}

.TwoItems {
    height: 180px;
    width: 100%;
    object-fit: cover;
    object-position: inherit;
}

@media (min-width: 768px) {
    .TwoItems {
        height: 400px;
        object-fit: cover;
        object-position: inherit;
    }
}

@media (min-width: 768px) {
    .OneItem {
        height: 400px;
        object-fit: cover;
        object-position: inherit;
    }
}

.OneItem {
    width: 100%;
    object-fit: cover;
    object-position: inherit;
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px !important;
    }
}

@media (min-width: 1024px) {
    .xl\:container {
        max-width: 1024px !important;
    }
}
