html {
    height: 100%;
}
body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
footer {
    margin-top: auto;
}
h3, h4, h5, h6 {
    font-family: 'Neuzeit Grotesk', sans-serif;
    font-weight: 700 !important;

}
h1, h2 {
    font-family: "Bradford Medium", sans-serif;
    font-weight: 500 !important;
}
h1, .h1 {
    font-size: 2.25rem !important;
    line-height: 2.75rem !important;
  }
  
  h2 {
    font-size: 1.625rem !important;
    line-height: 1.875rem !important;
  }
  
  h3 {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }
  
  h4 {
    font-size: 1.125rem !important;
    line-height: 1.375rem !important;
  }
  
  h5 {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }

  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 3rem !important;
      line-height: 3.625rem !important;
    }
    h2 {
        font-size: 2rem !important;
        line-height: 2.375rem !important;
      }
      
      h3 {
        font-size: 1.25rem !important;
        line-height: 1.5rem !important;
      }
      
      h4 {
        font-size: 1.125rem !important;
        line-height: 1.375rem !important;
      }
      
      h5 {
        font-size: 1rem !important;
        line-height: 1.188rem !important;
      }
  }