<template>
    <agile
        :infinite="true"
        :autoplay="true"
        :autoplaySpeed="3000"
        :navButtons="false"
        :speed="800"
        :centerMode="true"
        :options="myOptions"
        :slidesToShow="2"
        :timing="'ease-in-out'"
    >
        <div v-for="(caption, index) in captions" :key="index">
            <img class="slide px-6 mx-auto" :src="caption">
        </div>
    </agile>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    name: 'LogoSlider',
    components: {
        agile: VueAgile,
    },
    data(){
        return { 
            myOptions: {
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow:3
                        },
                    },
                    {
                        breakpoint: 1023,
                        settings: {
                            slidesToShow:4
                        }
                    }
                ]
            }
        }
    },
    props: {
        images: {
            type: String,
            default: '',
        },
    },
    computed: {
        captions() {
            return JSON.parse(this.images)
        },
    },
}
</script>

<style scoped>
.slide {
    max-height: 200px;
    width: auto;
}
</style>
