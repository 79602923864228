<template>
    <agile
        :infinite="true"
        :autoplay="true"
        :navButtons="false"
        :speed="1000"
        :centerMode="true"
        :options="myOptions"
        :slidesToShow="1"
        :timing="'ease-in-out'"
        :dots="false"
    >
    
        <div v-for="(caption, index) in captions" :key="index" class="flex flex-col  h-full rounded-3xl  ">
            <a v-if="caption.render_link != ''" :href="caption.render_link" :target="caption.render_link_target" class="h-full">
                <div class="h-1/2 flex items-center justify-center w-full">
                <img class=" object-cover h-full w-full rounded-3xl" :src="caption.image">
                 </div>
                <div class=" h-1/2 flex flex-col">
            <h3 class="p-4 blogtext font-bold text-center">{{caption.header}}</h3>
            <div v-html="caption.bodytext" class="p-4  grow h-full rounded-3xl  text-center">
            
            </div>
          </div>
            </a>
            
        </div>
 
       
    </agile>

</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    name: 'ArticleCarousel',
    components: {
        agile: VueAgile,
    },
    data(){
        return {
            myOptions: {
            }
        }
    },
    props: {
        records: {
            type: String,
            default: '',
        }, 
        
    },
    computed: {
        captions() {
            return JSON.parse(this.records)
        },
       
    },
}
</script>

<style scoped>
.slide {
    /* max-height: 200px; */
    width: auto;
}

:global(.carousel-custom .agile__dot button){
    width: 10px;
    height: 10px;
    background-color:black;
    border-radius: 5px;
    margin: 10px 4px 0 2px;
}

:global(.carousel-custom .agile__dot--current button){
    background-color: #ffd400;
}
:global(.carousel-custom .agile__dot button:hover){
    background-color: #ffe97b;
}

</style>

