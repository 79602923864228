<template>
    <svg
        id="uuid-0c9540a5-e924-4003-a5ba-0727fa3ce138"
        data-name="Ebene 4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024.8999634 1053.0841133"
        style="width: 100%; height: 100%"
    >
        <g>
            <g>
                <path
                    class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                    d="M844.4900513,883.1700401c32.9499512,9.5100098,60.2099609,37.0599976,60.2099609,82.5,0,108.3499756-154.2199707,114.7399902-172.3900146,19.1700439,23.5700073-14.0599976.9899902-57.0900269,54.0999756-81.3699951,0-.1400146,0-.2800293-.1400146-.710022,1.1400146,4.2600098,2.4100342,9.3699951,4.1199951,15.7600098-15.4799805,8.0900269-26.9799805,23.8599854-26.9799805,47.1500244,0,71.5700073,108.2099609,71.5700073,108.2099609,0,0-31.8099976-21.4399414-49.4199829-45.1600342-52.9700317-2.9799805-14.0599976-5.1099854-24.2800293-7.0999756-33.3699951h.4300537c2.5600586,8.9500122,3.5500488,15.6199951,3.5500488,15.7600098,5.6800537,0,13.7700195.8499756,18.8900146-7.9500122.8499756-1.4199829,1.6999512-2.7000122,2.2700195-3.9799805l-.0100098.0099487Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M837.8200073,867.9800377c10.2199707,2.4099731,10.6500244,7.0999756,6.6700439,15.0499878-.5699463,1.2800293-1.2800293,2.5599976-2.1300049,4.1199951-5.1099854,8.7999878-13.2099609,7.9500122-18.8900146,7.9500122,0-.1400146-.9899902-6.8200073-3.5500488-15.7600098-.4300537-1.7000122-.9899902-3.5499878-1.5600586-5.25v-.1400146c-.7099609-2.4099731-1.5600586-4.8300171-2.6999512-7.5300293h1.1400146c3.5500488.1400146,14.7700195.1400146,21.0200195,1.5599976v.000061Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M839.6599731,976.6099815c3.1199951,15.6199951-28.2600098,23.7099609-32.5200195,7.0999756-7.6700439-30.1099854-13.0600586-50.8400269-16.7600098-65.1799927-1.5600586-6.25-2.9799805-11.5-4.1199951-15.7600098v-.1400146c-.2800293-2.2700195-1.2800293-8.7999878-4.539978-16.4699707-1.5599976-3.6900024-3.6900024-7.6699829-6.3900146-11.6400146h-.1400146c-.4299927-.710022-.8499756-1.5599976-1.4199829-2.4099731l5.539978-4.6900024c-5.960022-36.9199829-3.1199951-31.5300293-10.9299927-31.5300293l-1.1400146-.8499756.8499756-1.2800293,11.2199707-15.0499878-6.960022-51.6900024c-1.5599976,1.1400146-7.3800049,6.6699829-9.3699951,2.1300049l-53.960022-350.3299866c6.3900146,1.8500061,31.3800049-1.7000122,35.6400146-5.25h0c57.9399414,369.9200134,63.3299561,409.9700012,72.1400146,453.1399841h-1.1400146c.9899902,2.5599976,1.8499756,5.1099854,2.6999512,7.5300293v.1400146c.2800293,1.7000122.7099609,3.4099731,1.1400146,5.25,1.8499756,9.0900269,4.1199951,19.3099976,7.0999756,33.3699951,3.2700195,15.3400269,7.3800049,35.5,13.0600586,63.6199951l.0001221-.0100098Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M786.2700195,902.6300011q.0933838.0933228,0,0c.1400146.5700073.1400146.710022.1400146.8499756-53.25,24.1400146-30.5300293,67.1699829-54.0999756,81.3699951-2.1300049,1.2800293-4.4000244,2.2700195-7.2399902,2.9799805-14.6300049-36.9199829,3.9799805-97.9799805,50.1300049-113.3200073h.1400146c2.7000122,3.9799805,4.8300171,7.9500122,6.3900146,11.6400146,3.2700195,7.8099976,4.4000244,14.2000122,4.539978,16.4699707l-.000061.0100708Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M772.3500366,767.010006l6.960022,51.6900024-11.2199707,15.0499878-.8499756,1.2800293-63.9000244,86.3399658c2.2700195,8.6599731-1.9899902,15.0499878-2.7000122,16.1900024l-32.6599731,46.0099487c-11.3599854,13.6300049-28.1199951,21.4399414-45.7299805,21.4399414.4299927,0-321.3599854,1.1400146-337.8300171-1.4200439.1400146,0,.9899902-.5699463-6.1099854-1.1400146-5.8200073-.5699463-16.8999939-1.1400146-38.0599976-1.4200439-12.0700073-.2800293-27.2600098-.2800293-46.7200012-.2800293-10.5100098,0-19.1700134-8.5200195-19.1700134-19.1700439,0-15.3400269-.2799988-19.4500122,77.8200073-20.3099976,9.7999878-.1400146,20.730011-.1400146,33.0900269-.1400146,65.1799927,0,168.7000122.9899902,333.289978.9899902,19.7399902,0,25.4199829-14.4799805,48.1400146-46.8599854,4.2600098-6.25,6.6699829-14.2000122,6.6699829-18.3200073,0-4.9699707-.5700073-7.3800049,1.1400146-11.3599854h0c3.9799805-9.7999878,20.8699951-28.9699707,88.4700317-116.4400024,1.9899902,4.6900024,7.8099976-.9899902,9.3699951-2.1300049l-.0001221.0003052ZM204.1900635,989.3900108c6.1099854,0,7.9500122-5.5400391,7.9500122-7.9499512,0-10.5100098-13.0599976-7.9500122-24-7.9500122-6.1099854,0-7.9500122,5.539978-7.9500122,7.9500122,0,10.5100098,13.0599976,7.9499512,24,7.9499512Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M756.5900269,238.9000206c1.4199829,11.9299927,5.25,12.0700073-9.2299805,15.1900024-4.8300171-3.5499878-11.3599854-4.6900024-17.1799927-3.1199951l-1.2800293-5.6799927,27.6900024-6.3900146Z"
                />
                <path
                    class="uuid-823180d9-8a86-417b-95d5-9064f992f021"
                    d="M750.3400269,256.9300194c7.0999756-.4299927,13.4899902,2.5599976,13.4899902,11.6400146s-6.1099854,11.9299927-12.7800293,11.7900085c-8.5200195-.2799988-18.3200073-5.8200073-18.3200073-11.7900085s9.2299805-11.0799866,17.6099854-11.6400146h.000061Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M747.3599854,254.090023c1.1400146.7099915,2.1300049,1.7000122,2.9799805,2.8399963-8.3800049.5700073-17.75,5.9599915-17.75,11.6400146s9.7999878,11.5,18.3200073,11.7900085c-5.4000244,7.3800049-15.7600098,9.3699951-23.8599854,5.8200073-6.1099854-2.5599976-10.789978-8.3800049-10.789978-17.1799927,0-10.2200012,6.25-16.1900024,13.7700195-18.1799927,5.960022-1.4200134,12.5-.4299927,17.3200073,3.269989l.0099487-.0000305Z"
                />
                <path
                    class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                    d="M745.3699951,412.4300194c0,.2799988-.1400146.7099915-.5700073,1.1400146h0c-4.2600098,3.5499878-29.25,7.1000061-35.6400146,5.25-.9899902-.2799988-1.4199829-.5700073-1.5599976-1.1400146l-3.6900024-22.2900085c1.7000122-.2799988,3.5499878-.5700073,4.9699707-.7099915,15.1900024-2.269989,23.5700073-4.2600098,28.2600098-4.3999939,8.2399902-.5700073,5.4000244,4.3999939,8.2399902,22.1499939h-.0099487Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M727.0499878,324.5300255c5.960022,40.0499878,10.3699951,57.5100098,10.0800171,65.6099854-4.6900024.2799988-13.0599976,2.1300049-28.2600098,4.3999939-2.5599976-8.0899963-3.5499878-26.5499878-9.0900269-66.1700134l.1400146-1.4200134c2.9799805.2799988,7.2399902-.5700073,13.6300049-1.4200134,6.1099854-.8500061,10.3699951-1.1400146,13.210022-1.9899902l.2800293.9899902.0099487.000061Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M728.7600098,245.2900047l1.2800293,5.6799927c-7.5300293,1.9899902-13.7700195,7.9500122-13.7700195,18.1799927,0,8.7999878,4.6900024,14.6300049,10.789978,17.1799927,5.25,28.6900024,6.6699829,35.0799866-.4299927,37.3500061-2.8400269.9899902-7.0999756,1.1400146-13.210022,1.9899902-6.3900146.8500061-10.5100098,1.8500061-13.6300049,1.4200134-9.2299805-.9899902-7.5300293-13.0599976-16.6099854-71.2900085l45.5800171-10.5100098v.0000305Z"
                />
                <path
                    class="uuid-823180d9-8a86-417b-95d5-9064f992f021"
                    d="M674.5100098,885.590023h0c-1.5599976,4.1199951-1.1400146,6.5300293-1.1400146,11.5,0,4.1199951-2.2700195,12.0700073-6.6699829,18.3200073-22.7199707,32.3800049-28.4000244,46.8599854-48.1400146,46.8599854-164.5800171.1400146-268.1099854-.9899902-333.2900391-.9899902-.1400146-3.4099731-.4299927-6.5300293-.8499756-9.6599731,349.6199951,0,342.9400024,3.4099731,349.6199951-6.25,32.9500122-47.5700073,30.5300293-39.9000244,28.1199951-53.3900146-.8499756-5.1099854-1.2800293-8.3800049-2.5599976-9.3699951l13.7700195,2.4099731,1.1400146.5700073Z"
                />
                <path
                    class="uuid-0d55ea13-a315-4a91-90fa-dd448de0dcdf"
                    d="M662.1600342,891.9800377c2.4099731,13.4899902,4.8300171,5.8200073-28.1199951,53.3900146-6.6699829,9.5100098,0,6.25-349.6199951,6.25h-3.4099731c-4.2600098,0-7.3800049-2.1300049-9.230011-4.4000244,4.5400085-.4299927,8.3799744-.8499756,11.6399841-1.5599976,2.8400269-.5700073,5.4000244-1.4199829,7.6699829-2.7000122,2.9799805-1.5599976,5.6799927-3.8300171,8.2399902-7.0999756-3.1199951-5.1099854-6.1099854-10.0800171-9.0900269-14.7700195,0-.1400146-.1400146-.1400146-.1400146-.2800293l.5700073-.4299927h332.5799561c22.8599854-25.7000122,31.2399902-36.7800293,34.789978-37.9199829l1.4199829.2800293c1.2800293.710022,1.7000122,4.1199951,2.7000122,9.2299805l.0001221.0100098Z"
                />
                <path
                    class="uuid-823180d9-8a86-417b-95d5-9064f992f021"
                    d="M290.25,920.9500084c2.9799805,4.6900024,5.960022,9.6599731,9.0900269,14.7700195-2.5599976,3.2700195-5.1099854,5.6799927-8.2399902,7.0999756h0c-2.5599976-4.2600098-4.8300171-8.5200195-7.3800049-12.6400146-20.1600037-34.2200317-43.4500122-64.4700317-110.9100037-51.9700317-.2799988,0-40.6100159,7.3800049-40.6100159,7.9500122l-.4299927-.2800293c1.8500061-3.1199951,3.6900024-6.5300293,5.6799927-10.0800171,93.7200012-23.710022,121.980011-2.9799805,152.5100098,44.8699951.1400146,0,.1400146.1400146.2800293.2800293l.0099487.000061Z"
                />
                <path
                    class="uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1"
                    d="M290.960022,942.8200035h0c-2.2700195,1.2800293-4.8300171,2.1300049-7.6699829,2.7000122-3.2700195.710022-7.0999756,1.1400146-11.6400146,1.5599976-4.2600098.4299927-9.0899963.710022-14.6300049,1.2800293-18.1799927-61.4899902-96.1400146-69.1599731-128.5100098-9.2299805-3.980011,7.2399902-9.9400024,8.7999878-15.8999939,9.2299805-2.5599976.1400146-5.1099854,0-7.5299988,0,0-19.5999756-4.8299866-7.8099976,26.7000122-62.6199951l.4299927.2800293c0-.5700073,40.3300171-7.9500122,40.6099854-7.9500122,67.3099976-12.5,90.6000061,17.75,110.9100342,51.9699707,2.4099731,4.2600098,4.8300171,8.5200195,7.2399902,12.7800293l-.0100098-.000061Z"
                />
                <path
                    class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                    d="M284.4199829,951.4800377c.4299927,3.1199951.710022,6.3900146.8499756,9.6599731-12.3500061,0-23.2900085,0-33.0899963.1400146-4.2600098-67.1699829-108.0700073-65.75-108.0700073,4.4000244,0,56.5200195,67.8800049,68.4499512,96.1399841,35.5,21.019989.2800293,32.2399902.8499756,38.0599976,1.4200439-32.6600037,76.5400391-167.1400146,64.3299561-167.1400146-36.9199829,0-6.1099854.4299927-11.789978,1.4200134-17.1799927,6.1099854-.2800293,11.9299927-1.9899902,15.8999939-9.2299805,32.519989-59.9299927,110.480011-52.2600098,128.5100098,9.2299805,5.5400085-.5700073,10.3699951-.9899902,14.6300049-1.2800293,1.8500061,2.2700195,5.1099854,4.4000244,9.2299805,4.4000244,1.2800293-.1400146,2.4099731-.1400146,3.5499878-.1400146l.0100708-.000061Z"
                />
                <path
                    class="uuid-0d55ea13-a315-4a91-90fa-dd448de0dcdf"
                    d="M212.1400146,981.4400597c0,2.4100342-1.8500061,7.9499512-7.9500122,7.9499512-10.9299927,0-24,2.5600586-24-7.9499512,0-2.5599976,1.8500061-7.9500122,7.9500122-7.9500122,10.9299927,0,24-2.5599976,24,7.9500122Z"
                />
            </g>
            <g>
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M826.460022,912.7100182c23.7099609,3.5499878,45.1600342,21.1599731,45.1600342,52.9700317,0,71.5699463-108.210022,71.5699463-108.210022,0,0-23.289978,11.5-39.0499878,26.9800415-47.1500244"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M819.500061,879.340023h.4300537"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M844.4900513,883.1700401c32.9499512,9.5100098,60.2099609,37.0599976,60.2099609,82.5,0,108.3499756-154.2199707,114.7399902-172.3900146,19.1700439"
                />
                <line
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    x1="709.0200195"
                    y1="418.8200035"
                    x2="762.9799805"
                    y2="769.1400108"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M816.7999878,866.5599937c-8.6600342-43.0299683-14.0600586-83.2200317-72.1400146-453.1400146"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M786.2700195,902.7700157c1.1399536,4.2600098,2.4099731,9.3699951,4.1200562,15.7600098,3.6899414,14.3400269,8.9499512,34.9299927,16.7600098,65.1800537,4.2600098,16.6099854,35.6400146,8.6600342,32.5200195-7.0999756-5.6800537-28.1199951-9.8000488-48.2800293-13.2099609-63.9000244-2.9799805-14.0599976-5.1099854-24.2800293-7.0999756-33.3699951-.4300537-1.8499756-.7099609-3.5499878-1.1400146-5.25"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M768.3800049,835.8900108c7.8099976,0,4.9699707-5.539978,10.9299927,31.5300293l-5.539978,4.6900024c.5700073.8499756.9899902,1.5599976,1.4199829,2.4099731"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M819.9299927,879.340023c2.5600586,8.9500122,3.5500488,15.6199951,3.5500488,15.7600098,5.6800537,0,13.7700195.8499756,18.8900146-7.9500122.8499756-1.4199829,1.5600586-2.8400269,2.1300049-4.1199951,3.9799805-7.9500122,3.5500488-12.6400146-6.6700439-15.0499878-6.1099854-1.4199829-17.3199463-1.5599976-21.0200195-1.4199829h-1.1400146c.9899902,2.5599976,1.8499756,5.1099854,2.6999512,7.5300293v.1400146c.5699463,1.7000122,1.1400146,3.4099731,1.5600586,5.1099854v-.000061Z"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M786.2700195,902.7700157c.1400146.4299927.1400146.710022.1400146.710022-53.25,24.1400146-30.5300293,67.1699829-54.0999756,81.3700562-2.1300049,1.2800293-4.4000244,2.2700195-7.2399902,2.9799805-14.6300049-36.9199829,3.9799805-97.9799805,50.1300049-113.3200073h.1400146c2.7000122,3.9799805,4.8300171,7.9500122,6.3900146,11.6400146,3.2700195,7.8099976,4.4000244,14.2000122,4.539978,16.6099854q.0933838,0,0,0l-.000061.0099487Z"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M240.2600098,1001.1800499c-28.2600098,32.9499512-96.1400146,21.1600342-96.1400146-35.5,0-70.0100098,103.8099976-71.4299927,108.0700073-4.2600098"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M284.4199829,951.4800377c.4299927,3.1199951.710022,6.3900146.8499756,9.6599731"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M278.3200073,1002.5999718c-32.6600037,76.5400391-167.1400146,64.3299561-167.1400146-36.9199829,0-6.1099854.4299927-11.789978,1.4200134-17.1799927"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M252.1900024,961.4200401c-78.1000061.710022-77.8200073,4.9699707-77.8200073,20.3100586,0,10.6500244,8.6600037,19.1700439,19.1700134,19.1700439,19.4500122,0,34.6499939.1400146,46.7200012.2800293,21.019989.2800293,32.2399902.8499756,38.0599976,1.4200439,7.0999756.7099609,6.25,1.1400146,6.1099854,1.1400146,16.4699707,2.5600586,338.2600098,1.4200439,337.8299561,1.4200439,17.6099854,0,34.5100098-7.8100586,45.7299805-21.4399414l32.6599731-46.0100098c.710022-1.1400146,5.1099854-7.5300293,2.7000122-16.1900024l63.9000244-86.3400269.8499756-1.2800293,11.2199707-15.0499878-6.960022-51.6900024c-1.5599976,1.1400146-7.3800049,6.6699829-9.3699951,2.1300049-67.5900269,87.4799805-84.4899902,106.6500244-88.4700317,116.4400024h0c-1.5599976,3.9799805-1.1400146,6.3900146-1.1400146,11.3599854,0,4.1199951-2.2700195,12.0700073-6.6699829,18.3200073-22.7199707,32.3800049-28.4000244,46.8599854-48.1400146,46.8599854-164.5800171.1400146-268.1099854-.9899902-333.289978-.9899902-12.3500061,0-23.2900085,0-33.0899963.1400146l.0001526-.0002441Z"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M204.1900024,989.3900108c-10.9299927,0-24,2.5600586-24-7.9499512,0-2.5599976,1.8500061-7.9500122,7.9500122-7.9500122,10.9299927,0,24-2.5599976,24,7.9500122,0,2.4100342-1.8500061,7.9499512-7.9500122,7.9499512Z"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M271.7900085,947.2200279c1.8500061,2.2700195,5.1100159,4.4000244,9.230011,4.4000244h3.4099731c349.6199951,0,342.9400024,3.4099731,349.6199951-6.25,32.9500122-47.5700073,30.5300293-39.9000244,28.1199951-53.3900146-.8499756-5.1099854-1.2800293-8.3800049-2.5599976-9.3699951-.2800293-.4299927-.8499756-.4299927-1.4199829-.2800293-3.5499878,1.1400146-11.9299927,12.210022-34.789978,37.9199829h-332.7200317"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M708.8800049,394.5400047c-2.5599976-8.0899963-3.5499878-26.5499878-9.0900269-66.1700134"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M727.0499878,324.5300255c5.960022,40.0499878,10.3699951,57.5100098,10.0800171,65.6099854"
                />
                <polyline
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    points="658.039978 882.3200035 659.6000366 882.6099815 673.3800049 885.0200157"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M708.8800049,394.5400047c-1.5599976.2799988-3.2700195.4299927-4.9699707.7099915l3.6900024,22.2900085c0,.4299927.5700073.8500061,1.5599976,1.1400146,6.3900146,1.8500061,31.3800049-1.7000122,35.6400146-5.25h0c.4299927-.4299927.710022-.7099915.5700073-1.1400146-2.8400269-17.75,0-22.5799866-8.2399902-22.2900085-4.6900024.4299927-12.9199829,2.269989-28.2600098,4.5400085h.0099487Z"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M727.2000122,286.3300133c5.25,28.6900024,6.6699829,35.0800171-.4299927,37.3500061-2.8400269.9899902-7.0999756,1.1400146-13.210022,1.9899902-6.3900146.8500061-10.5100098,1.8500061-13.6300049,1.4200134-9.2299805-.9899902-7.5300293-13.0599976-16.6099854-71.2900085l45.5800171-10.5100098,27.8300171-6.3900146c1.4199829,11.9299927,5.25,12.0700073-9.2299805,15.1900024"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M751.0499878,280.3600121c-5.4000244,7.3800049-15.7600098,9.3699951-23.8599854,5.8200073-6.1099854-2.5599976-10.789978-8.3800049-10.789978-17.1799927,0-10.2200012,6.25-16.1900024,13.7700195-18.1799927,5.8200073-1.4200134,12.3499756-.4299927,17.1799927,3.1199951,1.1400146.7099915,2.1300049,1.7000122,2.9799805,2.8399963"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M750.3400269,256.9300194c7.0999756-.4299927,13.4899902,2.5599976,13.4899902,11.6400146s-6.1099854,11.9299927-12.7800293,11.7900085c-8.5200195-.2799988-18.3200073-5.8200073-18.3200073-11.7900085s9.2299805-11.0799866,17.6099854-11.6400146h.000061Z"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M131.7700195,885.7300377c-31.5299988,54.960022-26.7000122,43.0299683-26.7000122,62.6199951,2.4100037,0,5.1099854.1400146,7.5299988,0,6.1099854-.2800293,11.9299927-1.9899902,15.8999939-9.2299805,32.519989-59.9299927,110.480011-52.2600098,128.5100098,9.2299805,5.5400085-.5700073,10.3699951-.9899902,14.6300049-1.2800293,4.539978-.4299927,8.3800049-.8499756,11.6400146-1.5599976,2.8400269-.5700073,5.4000244-1.4199829,7.6699829-2.7000122,2.9799805-1.5599976,5.6799927-3.8300171,8.2399902-7.0999756-3.1199951-5.1099854-6.1099854-10.0800171-9.0900269-14.7700195,0-.1400146-.1400146-.1400146-.1400146-.2800293-30.5299988-48-58.7900085-68.7299805-152.5100098-44.8699951-1.9899902,3.5499878-3.980011,6.8200073-5.6799927,9.9400024l.000061.000061Z"
                />
                <path
                    class="uuid-44711c23-433e-42d0-95f4-a2da855e768b"
                    d="M132.1900024,886.010006c0-.5700073,40.3300171-7.9500122,40.6099854-7.9500122,67.3099976-12.5,90.6000061,17.75,110.9100342,51.9700317,2.5599976,4.2600098,4.8300171,8.3800049,7.3800049,12.6400146"
                />
            </g>
        </g>
        <g>
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M22.6799927,82.8900108h71.0100098v19.4400024H22.6799927v61.5599976h71.0100098v19.4400024H0V3.2400093h93.6900024v19.4400024H22.6799927v60.2099991Z"
            />
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M173.0700073,127.1700096h-55.8900146v-18.6300049h55.8900146v18.6300049Z"
            />
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M215.7200012,135.0000114c1.0799866,20.5200043,12.1499939,31.3199921,31.8600159,31.3199921s34.5599976-12.4199982,34.5599976-30.5099945c0-15.6600037-8.1000061-22.9499969-36.4500122-32.4000092-21.3299866-7.2899933-28.3500061-10.8000031-36.4500122-18.0899963-9.1799927-8.3700027-14.0400085-20.25-14.0400085-34.0200043,0-30.5100021,21.8699951-51.2999992,53.730011-51.2999992,31.8599854,0,52.6500244,20.25,53.460022,51.5700035h-22.6799927c-.2700195-9.1800003-1.3499756-13.5-4.3200073-18.6299973-5.1300049-7.8300018-15.1199951-12.6899986-26.730011-12.6899986-17.8200073,0-30.5100098,12.1499977-30.5100098,29.4299965,0,8.6399994,3.7799988,16.1999969,10.2600098,20.7900009,5.6700134,4.0500031,10.5299988,6.2099991,28.8899841,12.6900024,19.1699829,6.7499924,28.3499756,11.609993,35.6400146,19.4399948,7.8300171,8.3699951,12.1500244,19.7099915,12.1500244,31.8600006,0,31.0500031-22.9500122,52.1100006-56.7000122,52.1100006-22.9500122,0-38.6099854-8.1000061-48.0599976-24.3000031-4.3200073-7.2899933-6.2099915-14.8500061-6.2099915-23.4900055v-3.7799988h21.6000061l-.0000305.0000153Z"
            />
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M434.960022,167.9400139c-14.039978,12.6900024-27.8099976,18.3600006-45.9000244,18.3600006-35.6400146,0-61.0200195-27-61.0200195-64.8000031s25.6500244-65.6100006,62.0999756-65.6100006c17.8200073,0,32.4000244,5.9400024,45.3599854,18.6299973l-13.2299805,13.2300034c-9.4500122-8.6399994-19.9799805-12.9599991-31.5900269-12.9599991-24.5700073,0-41.5800171,18.9000015-41.5800171,45.9000015s17.0100098,46.7100067,41.3099976,46.7100067c12.1500244,0,21.8699951-4.0500031,31.8599854-13.5l12.6900024,14.0399933h.0001221Z"
            />
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M573.4700317,121.5000114c0,36.4499969-28.0800171,64.8000031-63.7199707,64.8000031s-62.9099731-27-62.9099731-65.3399963,25.9199829-65.0699997,63.7200317-65.0699997,62.9099731,27.2699966,62.9099731,65.6100082l-.000061-.0000153ZM467.9000244,121.2300072c0,27.5400085,17.0100098,46.1699982,42.3900146,46.1699982s42.1199951-19.1699982,42.1199951-46.1699982-16.7399902-46.4400024-41.8499756-46.4400024-42.6599731,18.6300049-42.6599731,46.4400024h-.000061Z"
            />
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M721.4199829,121.5000114c0,36.4499969-28.0800171,64.8000031-63.7199707,64.8000031s-62.9099731-27-62.9099731-65.3399963,25.9199829-65.0699997,63.7200317-65.0699997,62.9099731,27.2699966,62.9099731,65.6100082l-.000061-.0000153ZM615.8499756,121.2300072c0,27.5400085,17.0100098,46.1699982,42.3900146,46.1699982s42.1199951-19.1699982,42.1199951-46.1699982-16.7399902-46.4400024-41.8499756-46.4400024-42.6599731,18.6300049-42.6599731,46.4400024h-.000061Z"
            />
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M773.2600098,157.9500084c.2700195,6.2100067,1.8900146,7.5599976,9.4500122,7.5599976h9.4499512v17.8200073h-19.1699829c-14.5800171-.2700043-20.25-5.6699982-20.25-19.4400024v-86.9400024h-17.2800293v-18.0899963h17.2800293V19.7100105h20.5200195v39.1500015h18.8999634v18.0899963h-18.9000244v81h.000061Z"
            />
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M830.7699585,127.7100182c1.0799561,10.5299988,2.9699707,15.9299927,8.0999756,22.6799927,8.0999756,11.0700073,19.9799805,17.0099945,33.4799805,17.0099945,15.3900146,0,29.9699707-8.3699951,37.2600098-21.3300018l18.0899658,6.75c-10.2600098,20.7900085-31.5899658,33.4799957-55.3499756,33.4799957-36.9899902,0-63.4499512-27-63.4499512-64.8000031s26.1899414-65.6100006,62.6400146-65.6100006,63.1800537,27.2700043,63.1800537,63.7200012c0,1.6199951,0,5.1300049-.2700195,8.1000061h-103.6800537v.0000153ZM912.8499146,112.590023c-.8100586-9.7200012-2.6999512-15.1199951-7.0200195-21.3300018-7.8299561-10.5299988-20.5200195-17.0100021-34.2900391-17.0100021s-26.1899414,6.4800034-33.4799805,17.2800064c-3.7800293,5.9400024-5.4000244,11.0700073-7.0200195,21.0599976h81.8100586Z"
            />
            <path
                class="uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a"
                d="M984.9400024,58.8600121v21.0599976c3.7800293-8.9100037,6.4799805-12.6900024,11.3399658-16.4700012,6.2099609-4.8600006,14.8499756-7.5599976,23.7600098-7.5599976h4.8599854v20.7900009c-2.1600342-.2699966-4.3199463-.2699966-5.6700439-.2699966-11.0699463,0-20.7900391,5.1299973-27,14.3099976-5.4000244,7.8300018-7.2900391,15.1199951-7.2900391,29.4300079v63.1799927h-20.5200195V58.8600121h20.5201416Z"
            />
        </g>
    </svg>
</template>

<style type="text/css">
.uuid-0d55ea13-a315-4a91-90fa-dd448de0dcdf {
    fill: #1d1d1b;
}

.uuid-0d55ea13-a315-4a91-90fa-dd448de0dcdf,
.uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1,
.uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a,
.uuid-823180d9-8a86-417b-95d5-9064f992f021 {
    stroke-width: 0px;
}

.uuid-0be52339-bf42-49e6-93b7-d7f62a6a1fd1 {
    fill: #ffcf1a;
}

.uuid-44711c23-433e-42d0-95f4-a2da855e768b {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}

.uuid-fd4c1d14-66bf-4155-b4b8-ed735979658a {
    fill: #000;
}

.uuid-823180d9-8a86-417b-95d5-9064f992f021 {
    fill: #fbfbfb;
}
</style>
