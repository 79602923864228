<template>
    <svg
        id="uuid-ad692fe7-de90-410b-afd3-b20b897751eb"
        data-name="Ebene 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1366.0999084 1057.2799461"
        style="width: 100%; height: 100%"
    >
        <path
            class="uuid-886a2788-ace6-4879-8bb9-c98d10904ac3"
            d="M1102.2499816,530.529995c-145.0999756,0-262.7999878,117.7000122-262.7999878,262.7999878s117.7000122,262.7999878,262.7999878,262.7999878,262.7999268-117.7000122,262.7999268-262.7999878-117.6999512-262.7999878-262.8000488-262.7999878h.0001221ZM1102.2499816,1010.4300194c-119.9000244,0-217-97.2000122-217-217.0999756s97.1999512-217.0999756,217-217.0999756,217,97.2000122,217,217.0999756-97.1999512,217.0999756-217,217.0999756Z"
        />
        <circle
            class="uuid-a427f7c7-02ec-4f04-97bc-0717f24031a8"
            cx="1102.2499816"
            cy="793.4300194"
            r="62"
        />
        <path
            class="uuid-886a2788-ace6-4879-8bb9-c98d10904ac3"
            d="M263.8499877,530.529995C118.7499816,530.529995,1.05,648.2300072,1.05,793.4300194s117.6999969,262.7999268,262.7999878,262.7999268,262.8000183-117.7000122,262.8000183-262.7999878-117.7000122-262.7999878-262.7999878-262.7999878v-.0999756h-.0000305ZM263.8499877,1010.4300194c-119.8999939,0-217-97.2000122-217-217.0999756s97.1999969-217.0999756,217-217.0999756,216.9999695,97.2000122,216.9999695,217.0999756-97.2000122,217.0999756-217,217.0999756h.0000305Z"
        />
        <circle
            class="uuid-a427f7c7-02ec-4f04-97bc-0717f24031a8"
            cx="263.8499877"
            cy="793.4300194"
            r="62"
        />
        <path
            class="uuid-17c6698e-b6ca-41cf-8462-e5c1eeed13b4"
            d="M923.6500061,348.6300011l-42.7999878,14.6000061,3.5,9.8999939-369.7000122,135.3999939-10.9000244-25.7999878-27.2000122.2000122,11.4000244,34.7000122c2.2000122,6.7999878.5999756,14.2000122-4.2999878,19.4000244l-244.2000122,247.6000366c-7.1000061,7.7000122-7.1000061,19.5999756.2000122,27.2000122,3.2999878,3.4000244,7.7000122,5.5999756,12.3999939,6l296.1000061,42.9000244h0c39.7000122,15.9000244,85.0999756,3.9000244,111.7999878-29.5l270.6000366-338.7000122,11.0999756,22.5,42.8000488-19-60.7999878-147.2999878v-.1000061l-.000061-.0001221ZM555.8500183,800.9300194l-1.7000122,2c-15.5999756,18.4000244-27.5,29.0999756-59.9000244,25l-184.1000061-25c-9.7000122-.5999756-17.8999939-7.2000122-20.7000122-16.5h0c-2.3999939-7.9000244-.2999878-16.5,5.3999939-22.5l201.7999878-199.7000122,70.5,179.3999634c6.5,19.7999878,2.2000122,41.5-11.2000122,57.2999878h-.0999756l.000061.000061ZM867.4500549,433.8300133l-255.4000244,317.0000305-82.4000244-204.9000244,328.7999878-124.7999878c7.5999756-2.7000122,14,6.2999878,8.9000244,12.6000061l.0999756.1000061.000061-.0000305Z"
        />
        <path
            class="uuid-886a2788-ace6-4879-8bb9-c98d10904ac3"
            d="M865.5499694,436.529995l12.7999878,25.6000061c9.0999756,18.1999817,6.7999878,39.9999695-5.9000244,55.8000183l-159,199.2999878c-12.5999756,15.7999878-31.7000122,25.0999756-51.9000244,25.2000122l-42.9000244.2999878-5-33.5c-1-20.5999756,6.2000122-40.7999878,20.0999756-56.0999756l173.5999756-195.6000061c9.0999756-10.2999878,21.7000122-16.7999878,35.4000244-18.2999878,11.2999878-1.2999878,22.9000244-2.6000061,22.9000244-2.6000061l-.0999756-.1000061.000061-.0000305Z"
        />
        <g>
            <circle
                class="uuid-c2482cab-f3ce-4c04-9312-9714c40a9f4c"
                cx="610.6500061"
                cy="867.7300072"
                r="69.2999878"
            />
            <path
                class="uuid-c2482cab-f3ce-4c04-9312-9714c40a9f4c"
                d="M739.4499938,869.2300072c-.4000244,6.7999878-5.9000244,12.0999756-12.7000122,12.0999756l-117.2999878.5c-7.2999878,0-13.2000122-5.7999878-13.2999878-13v-.2999878c-.0999756-7.4000244,5.7999878-13.4000244,13.2000122-13.4000244l117.4000244.2999878c7.2999878,0,13.0999756,6.2000122,12.7000122,13.5v.4000244-.0999756h-.000061Z"
            />
            <path
                class="uuid-886a2788-ace6-4879-8bb9-c98d10904ac3"
                d="M699.1500061,863.1300316h50.5c4.5999756,0,8.2999878,3.7000122,8.2999878,8.2999878v1.2000122c0,4.5999756-3.7000122,8.2999878-8.2999878,8.2999878h-50.5c-4.5999756,0-8.2999878-3.7000122-8.2999878-8.2999878v-1.2000122c0-4.5999756,3.7000122-8.2999878,8.2999878-8.2999878Z"
            />
            <path
                class="uuid-a427f7c7-02ec-4f04-97bc-0717f24031a8"
                d="M606.8499572,869.1300316c.7999878,2.0999756,3.0999756,3.2000122,5.2000122,2.4000244,2.0999756-.7999878,3.2000122-3.0999756,2.4000244-5.2000122-.7999878-2.0999756-3.0999756-3.2000122-5.2000122-2.4000244s-3.2000122,3.0999756-2.4000244,5.2000122Z"
            />
        </g>
        <polygon
            class="uuid-c2482cab-f3ce-4c04-9312-9714c40a9f4c"
            points="434.9499633 356.2300072 476.9499938 482.9300194 503.7499816 483.029995 462.7499816 356.2300072 434.9499633 356.2300072"
        />
        <path
            class="uuid-886a2788-ace6-4879-8bb9-c98d10904ac3"
            d="M496.5499694,316.529995h32.0999756c10.9000244,0,19.7999878,8.8999939,19.7999878,19.7999878s-2.2000122,10.3999939-5.7999878,14-8.5,5.7999878-14,5.7999878h-32.0999756"
        />
        <path
            class="uuid-886a2788-ace6-4879-8bb9-c98d10904ac3"
            d="M496.5499694,356.2300072h-123.2999878c-10.8999939,0-19.7999878-8.8999939-19.7999878-19.7999878s2.2000122-10.3999939,5.7999878-14c3.6000061-3.6000061,8.6000061-5.7999878,14-5.7999878h123.2999878c10.9000244,0,19.7999878,8.8999939,19.7999878,19.7999878s-2.2000122,10.3999939-5.7999878,14-8.5,5.7999878-14,5.7999878Z"
        />
        <path
            class="uuid-17c6698e-b6ca-41cf-8462-e5c1eeed13b4"
            d="M984.4499328,495.9300194l126,293.5999756c2.3000488,5.2999878.0999756,11.4000244-5,14h0c-5.0999756,2.5-11.1999512.7000122-14.0999756-4.2000122l-123.1999512-216.1000366c-8.9000244-15.2000122-15.7999878-31.5-20.7000122-48.4000244l-5.7999878-19.9000244,42.8000488-19h0l-.0001221.0001221Z"
        />
        <path
            class="uuid-886a2788-ace6-4879-8bb9-c98d10904ac3"
            d="M880.8499572,363.2300072l-5-15.5c-2.0999756-6.5.9000244-13.6000061,7-16.6000061l70.7999878-35.2999878c6.0999756-3,12.8999634-4.1000061,19.6000366-3.2000122l12.3000488,1.7000122c8.5999756,1.2000122,14.5,9.2000122,13.0999756,17.7000122h0c-1.3000488,8.3999939-9.1999512,14.1000061-17.5999756,12.7999878l-14.1999512-2.2000122-43.2999878,26-42.7999878,14.6000061h.0999756-.0001221Z"
        />
        <path
            class="uuid-c2482cab-f3ce-4c04-9312-9714c40a9f4c"
            d="M957.3499572,311.4300194l16.0999756-13c6.8000488-5.5,16.8000488-4.8999939,22.9000244,1.3999939h0c6.1999512,6.5,6.3000488,16.7000122.0999756,23.2999878l-16.3000488,17.2999878-22.7999878-29.1000061v.1000061l.000061.0000305Z"
        />
        <circle
            class="uuid-c2482cab-f3ce-4c04-9312-9714c40a9f4c"
            cx="967.9499328"
            cy="326.7300072"
            r="18.5999908"
        />
        <g>
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M156.2999694,135.0000114c1.0800018,20.5200043,12.1499939,31.3199921,31.8600006,31.3199921s34.5600128-12.4199982,34.5600128-30.5099945c0-15.6600037-8.1000061-22.9499969-36.4499969-32.4000092-21.3300018-7.2899933-28.3500061-10.8000031-36.4499969-18.0899963-9.1799927-8.3700027-14.0399933-20.25-14.0399933-34.0200043,0-30.5100021,21.8699951-51.2999992,53.7299957-51.2999992s52.6499939,20.25,53.4599915,51.5700035h-22.6799927c-.269989-9.1800003-1.3500061-13.5-4.3200073-18.6299973-5.1300049-7.8300018-15.1199951-12.6899986-26.7299957-12.6899986-17.8199921,0-30.5099945,12.1499977-30.5099945,29.4299965,0,8.6399994,3.7799988,16.1999969,10.2599945,20.7900009,5.6699982,4.0500031,10.5299988,6.2099991,28.8899994,12.6900024,19.1700134,6.7499924,28.3500061,11.609993,35.6400146,19.4399948,7.8299866,8.3699951,12.1499939,19.7099915,12.1499939,31.8600006,0,31.0500031-22.9500122,52.1100006-56.6999969,52.1100006-22.9499969,0-38.6100006-8.1000061-48.0599976-24.3000031-4.3200073-7.2899933-6.2100067-14.8500061-6.2100067-23.4900055v-3.7799988h21.6000061l-.0000305.0000153Z"
            />
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M327.4799621,127.1700096h-55.8900146v-18.6300049h55.8900146v18.6300049Z"
            />
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M372.5599792,183.329998h-22.6799927V3.2400055h48.0599976c42.6600037,0,66.1500244,19.4399986,66.1500244,55.079998,0,17.8199997-7.0200195,33.75-19.1700134,43.7400055-10.5299988,8.3699951-24.5700073,12.4199982-44.5499878,12.4199982h-27.8099976v68.8500061l-.0000305-.0000153ZM396.0499694,95.0400047c9.7200012,0,16.2000122-.8099976,22.4100037-3.2400055,13.769989-4.8600006,22.6799927-17.5500031,22.6799927-32.9400024s-8.6400146-27.8099976-22.9500122-32.6699982c-7.019989-2.4300003-14.3099976-3.5100021-25.1100159-3.5100021h-20.519989v72.3600082h23.4900208Z"
            />
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M498.9199646,127.7100029c1.0800171,10.5299988,2.9699707,15.9300079,8.0999756,22.6800079,8.0999756,11.0700073,19.9799805,17.0099945,33.4799805,17.0099945,15.3900146,0,29.9699707-8.3699951,37.2600098-21.3300018l18.0900269,6.75c-10.2600098,20.7900085-31.5900269,33.4799957-55.3499756,33.4799957-36.9899902,0-63.4500122-27-63.4500122-64.8000031s26.1900024-65.6100006,62.6400146-65.6100006,63.1799927,27.2700043,63.1799927,63.7200012c0,1.6199951,0,5.1300049-.2700195,8.1000061h-103.6799927ZM580.9999816,112.5900078c-.8099976-9.7200012-2.7000122-15.1199951-7.0200195-21.3300018-7.8300171-10.5299988-20.5200195-17.0100021-34.289978-17.0100021s-26.1900024,6.4800034-33.4799805,17.2800064c-3.7800293,5.9400024-5.4000244,11.0699921-7.0200195,21.0599976h81.8099976Z"
            />
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M725.7199523,3.2400055h20.5200195v180.0899925h-20.5200195v-21.3300018c-12.4199829,17.0099945-25.6500244,24.3000031-44.5499878,24.3000031-34.289978,0-58.5900269-26.7299957-58.5900269-64.5299988s24.8400269-65.8799973,58.5900269-65.8799973c18.3599854,0,32.4000244,6.75,44.5499878,21.8700027V3.2400055ZM644.1799743,122.0400047c0,27.2700043,16.4699707,46.4400024,39.960022,46.4400024s41.5800171-19.9799957,41.5800171-46.9799957-16.4699707-47.25-40.2299805-47.25-41.3099976,19.4400024-41.3099976,47.7899933h-.000061Z"
            />
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M799.4199646,127.7100029c1.0800171,10.5299988,2.9699707,15.9300079,8.0999756,22.6800079,8.0999756,11.0700073,19.9799805,17.0099945,33.4799805,17.0099945,15.3900146,0,29.9699707-8.3699951,37.2600098-21.3300018l18.0900269,6.75c-10.2600098,20.7900085-31.5900269,33.4799957-55.3499756,33.4799957-36.9899902,0-63.4500122-27-63.4500122-64.8000031s26.1900024-65.6100006,62.6400146-65.6100006,63.1799927,27.2700043,63.1799927,63.7200012c0,1.6199951,0,5.1300049-.2700195,8.1000061h-103.6799927ZM881.4999816,112.5900078c-.8099976-9.7200012-2.7000122-15.1199951-7.0200195-21.3300018-7.8300171-10.5299988-20.5200195-17.0100021-34.289978-17.0100021s-26.1900024,6.4800034-33.4799805,17.2800064c-3.7800293,5.9400024-5.4000244,11.0699921-7.0200195,21.0599976h81.8099976Z"
            />
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M931.4499938,183.329998V3.2400055h20.5200195v180.0899925h-20.5200195Z"
            />
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M1005.1600158,127.7100029c1.0799561,10.5299988,2.9699707,15.9300079,8.0999756,22.6800079,8.0999756,11.0700073,19.9799805,17.0099945,33.4799805,17.0099945,15.3900146,0,29.9699707-8.3699951,37.2600098-21.3300018l18.0899658,6.75c-10.2600098,20.7900085-31.5899658,33.4799957-55.3499756,33.4799957-36.9899902,0-63.4499512-27-63.4499512-64.8000031s26.1899414-65.6100006,62.6400146-65.6100006,63.1800537,27.2700043,63.1800537,63.7200012c0,1.6199951,0,5.1300049-.2700195,8.1000061h-103.6800537ZM1087.2399719,112.5900078c-.8100586-9.7200012-2.6999512-15.1199951-7.0200195-21.3300018-7.8299561-10.5299988-20.5200195-17.0100021-34.2900391-17.0100021s-26.1899414,6.4800034-33.4799805,17.2800064c-3.7800293,5.9400024-5.4000244,11.0699921-7.0200195,21.0599976h81.8100586Z"
            />
            <path
                class="uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8"
                d="M1235.7399719,167.9399986c-14.0400391,12.6900024-27.8100586,18.3600006-45.9000244,18.3600006-35.6400146,0-61.0200195-27-61.0200195-64.8000031s25.6500244-65.6100006,62.0999756-65.6100006c17.8199463,0,32.4000244,5.9400024,45.3599854,18.6299973l-13.2299805,13.2300034c-9.4499512-8.6399994-19.9799805-12.9599991-31.5899658-12.9599991-24.5699463,0-41.5799561,18.9000015-41.5799561,45.9000015s17.0100098,46.7099915,41.3100586,46.7099915c12.1500244,0,21.8699951-4.0500031,31.8599854-13.5l12.6899414,14.0399933v.0000153Z"
            />
        </g>
    </svg>
</template>
<style type="text/css">
.uuid-886a2788-ace6-4879-8bb9-c98d10904ac3,
.uuid-c2482cab-f3ce-4c04-9312-9714c40a9f4c,
.uuid-17c6698e-b6ca-41cf-8462-e5c1eeed13b4,
.uuid-a427f7c7-02ec-4f04-97bc-0717f24031a8 {
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2.0999999px;
}

.uuid-886a2788-ace6-4879-8bb9-c98d10904ac3,
.uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8 {
    fill: #000;
}

.uuid-c2482cab-f3ce-4c04-9312-9714c40a9f4c {
    fill: #fff;
}

.uuid-17c6698e-b6ca-41cf-8462-e5c1eeed13b4 {
    fill: #ffcf1a;
}

.uuid-c742e721-f53c-454f-9f5f-8d4e0de102c8 {
    stroke-width: 0px;
}

.uuid-a427f7c7-02ec-4f04-97bc-0717f24031a8 {
    fill: none;
}
</style>
