<template>
    <div class="visible md:hidden">
        <agile
        :infinite="true"
        :autoplay="true"
        :navButtons="false"
        :speed="1000"
        :centerMode="true"
        :options="myOptions"
        :slidesToShow="1"
        :timing="'ease-in-out'"
        :dots="false"
    >
    
        <div v-for="(caption, index) in captions" :key="index" class="flex flex-col   h-full rounded-3xl  ">
            <div class="flex flex-col py-4 xl:py-0">
                <div class="pb-4">
                    <div class="relative flex justify-center items-stretch w-full">
                        <img class=" object-contain lazy" :src="caption.image">
                    </div>
                   
                </div>
                    <div>
                            <h3 v-if="caption.header != ''" class="font-bold">{{caption.header}}</h3>
                            <div v-html="caption.bodytext" >
                            </div>
                        
                            <div  class="flex justify-evenly">
                                <div v-if="caption.cta_button_text != ''">
                                    <a  v-if="caption.render_button_link"  :href="caption.render_button_link">
                                    <span v-if="caption.cta_button_text != '' && caption.cta_button_text != ''"
                                    :class="buttonClasses(caption.cta_button_type) + ' ' + caption.call_to_action_button_size"
                                    class=" py-2 px-5 uppercase rounded-full mx-auto inline-block mt-4">
                                        {{caption.cta_button_text}}
                                </span>
                                </a>
                                <span :class="buttonClasses(caption.cta_button_type) + ' ' + caption.call_to_action_button_size"  class="py-2 px-5 uppercase rounded-full mx-auto inline-block mt-4" v-else>
                                {{caption.cta_button_text}}
                            </span>
                                </div>
                               
                                <div v-if="caption.cta_button_text_2 != ''">
                                    <a v-if="caption.render_button_link_2" :href="caption.render_button_link_2">
                                        <span :class="buttonClasses(caption.cta_button_type_2) + ' ' + caption.call_to_action_button_size_2 " class="uppercase rounded-full mx-auto inline-block mt-4">
                                            {{caption.cta_button_text_2}}
                                        </span>
                                    </a>
                            <span :class="buttonClasses(caption.cta_button_type_2) + ' ' + caption.call_to_action_button_size_2" class=" uppercase rounded-full mx-auto inline-block mt-4" v-else>
                                {{caption.cta_button_text_2}}
                            </span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
 
       
    </agile>

    </div>
   
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    name: 'TextImageCarousel',
    components: {
        agile: VueAgile,
    },
    data(){
        return {
            myOptions: {
            }
        }
    },
    props: {
        records: {
            type: String,
            default: '',
        }, 
        
    },
    computed: {
        captions() {
            return JSON.parse(this.records)
        },
     
    },
    methods: {
        buttonClasses(buttonType) {
            if(buttonType == 'Yellow') {
                return 'bg-primary text-black hover:bg-black px-4 hover:text-white'
            }
            if(buttonType == 'PrimaryDark') {
                return 'bg-black text-white hover:bg-primary hover:text-black'
            }
            if(buttonType == 'SecondaryLight') {
                return 'bg-opacity-0 text-black hover:bg-black hover:text-white border-2 border-black'
            }
            else return 'bg-primary text-black hover:bg-black px-4 hover:text-white'
    }
    }
}
</script>

<style scoped>
.slide {
    /* max-height: 200px; */
    width: auto;
}

:global(.carousel-custom .agile__dot button){
    width: 10px;
    height: 10px;
    background-color:black;
    border-radius: 5px;
    margin: 10px 4px 0 2px;
}

:global(.carousel-custom .agile__dot--current button){
    background-color: #ffd400;
}
:global(.carousel-custom .agile__dot button:hover){
    background-color: #ffe97b;
}

</style>

