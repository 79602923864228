<template>
    <agile
        :infinite="true"
        :autoplay="true"
        :navButtons="false"
        :speed="800"
        :centerMode="true"
        :options="myOptions"
        :slidesToShow="1"
        :timing="'ease-in-out'"
        :dots="true"
    >
        <div v-for="(caption, index) in captions" :key="index">
            <img class="slide px-6 mx-auto" :src="caption">
        </div>
    </agile>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    name: 'Carousel',
    components: {
        agile: VueAgile,
    },
    data(){
        return {
            myOptions: {
            }
        }
    },
    props: {
        images: {
            type: String,
            default: '',
        },
    },
    computed: {
        captions() {
            return JSON.parse(this.images)
        },
    },
}
</script>

<style scoped>
.slide {
    /* max-height: 200px; */
    width: auto;
}

:global(.carousel-custom .agile__dot button){
    width: 10px;
    height: 10px;
    background-color:black;
    border-radius: 5px;
    margin: 10px 4px 0 2px;
}

:global(.carousel-custom .agile__dot--current button){
    background-color: #ffd400;
}
:global(.carousel-custom .agile__dot button:hover){
    background-color: #ffe97b;
}

</style>
