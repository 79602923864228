/* TODO: Headlines! */

/* .rich-text h1 {
    font-size: 3rem !important
}

.rich-text h2 {
    font-size: 2rem !important
}

.rich-text h1,
h2 {
    line-height: 1.2 !important
}

.rich-text h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 900 !important
} */

.rich-text h1,
h2, h3 {
    margin: 5px 0 !important;
}

.rich-text ul {
    list-style: disc !important;
    margin-left: 1rem
}

.rich-text ol {
    padding: 0;
    counter-reset: item
}

.rich-text ol>li {
    margin: 0;
    padding: 0 0 0 1.5em;
    text-indent: -1.5em;
    list-style-type: none;
    counter-increment: item
}

.rich-text ol>li:before {
    display: inline-block;
    width: 1.5em;
    padding-right: .5em;
    font-weight: 700;
    text-align: right;
    content: counter(item) '.'
}

.rich-text p {
    margin-block-start: 1em;
    margin-block-end: 1em
}

.rich-text a {
    text-decoration: underline
}